import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './ui/dashboard/dashboard.component';
import { LoginComponent } from './ui/login/login.component';
import { WzPortalHubComponent } from './ui/wz-portal-hub/wz-portal-hub.component';
import {HowtosComponent} from './ui/howtos/howtos.component';
import {WhatsNextComponent} from './whats-next/whats-next.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'login/:id', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'wz-portal-hub', component: WzPortalHubComponent },
  { path: 'howtos', component: HowtosComponent },
  { path: 'whatsnext', component: WhatsNextComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
