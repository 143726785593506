import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IMyDateRange, IMyDateRangeModel, IMyDrpOptions } from 'mydaterangepicker';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as _ from 'lodash';
import * as fp from 'lodash/fp';
import { Chart } from 'chart.js';

import { DataHubService, LogDataTypes } from '../../server-connection/data-hub.service';
import { MessageService } from '../../message.service';
import { DBCollection, DBResult } from '../../server-connection/statistics-server.service';
import { UserAccountService } from '../../user-account.service';

enum SortOrder {
  desc = 'desc',
  asc = 'asc',
}

export class UserStatElement {
  public name: string;
  public value: number;
  public threshold: number;
  public selectedComparatorIndex: number;
  public comparators: any[];
  public isHealthy: boolean;
  public dailyData: number[];
  public dailyLabels: string[];
  public hourlyData: any[];
  public isExtended: boolean;

  public getComparator(start: Date, end: Date) {
    let daySpan = 1;
    if (start && end) {
      let dateSpan = Math.round((end.valueOf() - start.valueOf()) / (1000 * 60 * 60 * 24));
      daySpan = dateSpan;
    }

    //value auf einen Tag umrechnen
    let dailyValue = this.value / daySpan;

    let h = !this.comparators[this.selectedComparatorIndex].compare(dailyValue, this.threshold);
    this.isHealthy = h;
    //console.warn(this.name + " isHealthy: ", h);

    return this.comparators[this.selectedComparatorIndex];
  }
}

@Component({
  selector: 'app-user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.scss'],
})
export class UserStatisticsComponent implements OnInit, OnDestroy {
  @ViewChild('dayChart') dayChartCanvas: ElementRef;
  @ViewChild('hourChart') hourChartCanvas: ElementRef;

  currentUserName: string = 'nochnix';

  dayChart: any = null;
  hourChart: any = null;
  currentlySelectedElement: UserStatElement = null;
  hourlyChartSelectedFilter: number = 0;
  dailyChartSelectedFilter: number = 0;

  msgSubscription: Subscription;
  currentMap: string;

  statBoxes: any[] = [];

  navStarts: UserStatElement;
  destNotFound: UserStatElement;
  addToCart: UserStatElement;
  usageTime: UserStatElement;
  navTime: UserStatElement;
  uniqUsers: UserStatElement;

  destsCountDataSortProperty = 'count';
  destsCountDataSortOrder: string = 'desc';
  destsCountData: any[] = null;

  top5Users: any[] = null;
  suchbegriffe: any[] = null;
  notFoundDestinations: any[] = null;

  suchbegriffeSorting: {
    resultsSortedAsc: boolean;
    occurancesSortedAsc: boolean;
    lastSearchedSortedAsc: boolean;
  } = {
    resultsSortedAsc: null,
    occurancesSortedAsc: true,
    lastSearchedSortedAsc: null,
  };

  selectedFilterObj: any;
  dataFilters: any[];

  settingsOpen: boolean = false;
  isSuchbegriffeContainerLarge: boolean = false;

  rangePickerOptions: IMyDrpOptions = {
    dateFormat: 'dd.mm.yyyy',
    editableDateRangeField: false,
    openSelectorOnInputClick: true,
  };
  dateRangeModel: IMyDateRange;

  private subscriptions = [];
  private startDate: Date;
  private endDate: Date;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private dataService: DataHubService,
    private msgService: MessageService,
    private accountService: UserAccountService
  ) {
    this.accountService.currentUser$
      .pipe(
        takeUntil(componentDestroyed(this)),
        filter((item) => !!item)
      )
      .subscribe((user) => {
        this.currentUserName = user.username;
      });

    this.dataFilters = [
      {
        name: 'Alle',
        id: 0,
      },
      {
        name: 'nur App',
        id: 1,
      },
      {
        name: 'nur Terminals',
        id: 2,
      },
      {
        name: 'nur Indoor Maps',
        id: 3,
      },
    ];
    this.selectedFilterObj = this.dataFilters[0];

    this.initUserStatElements();

    this.currentMap = this.dataService.getCurrentMap();

    let today: Date = new Date();
    let weekAgo: Date = new Date(today);
    weekAgo.setDate(today.getDate() - 7);

    this.startDate = weekAgo;
    this.endDate = today;

    this.updateDateRangeModel();

    this.msgSubscription = this.msgService.missionAnnounced$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((mission) => {
        if (mission != '') {
          let msgObj = JSON.parse(mission);

          if (msgObj.type == 'map') {
            this.currentMap = msgObj.data;
            this.closeDiagrams();
            this.reloadData();
          } else if (msgObj.type == 'dateRangeChange') {
            this.startDate = new Date(Date.parse(msgObj.data.start));
            this.endDate = new Date(Date.parse(msgObj.data.end));
            this.updateDateRangeModel();
            this.reloadData();
          }
        }
      });
  }

  updateDateRangeModel() {
    this.dateRangeModel = {
      beginDate: {
        year: this.startDate.getFullYear(),
        month: this.startDate.getMonth() + 1,
        day: this.startDate.getDate(),
      },
      endDate: {
        year: this.endDate.getFullYear(),
        month: this.endDate.getMonth() + 1,
        day: this.endDate.getDate(),
      },
    };
  }

  updateChartsData(box: UserStatElement) {
    // daily chart
    if (box.dailyLabels && box.dailyLabels.length > 0 && box.dailyData && box.dailyData.length > 0) {
      this.dayChart.data.labels = box.dailyLabels;
      this.dayChart.data.datasets.forEach((dataset) => {
        dataset.data = box.dailyData[this.dailyChartSelectedFilter];
      });
    } else {
      this.dayChart.data.labels = ['0'];
      this.dayChart.data.datasets.forEach((dataset) => {
        dataset.data = [0];
      });
    }
    this.dayChart.update();

    // hourly chart
    if (box.hourlyData && box.hourlyData.length > 0) {
      this.hourChart.data.datasets.forEach((dataset) => {
        dataset.data = box.hourlyData[this.hourlyChartSelectedFilter];
      });
    } else {
      this.hourChart.data.datasets.forEach((dataset) => {
        dataset.data = [0];
      });
    }
    this.hourChart.update();
  }

  updateCharts(elem: UserStatElement) {
    if (this.dayChart && this.hourChart && elem) {
      this.updateChartsData(elem);
    } else {
      this.createCharts();
    }
  }

  createCharts() {
    let htmlRefDay = this.dayChartCanvas.nativeElement;

    this.dayChart = new Chart(htmlRefDay, {
      type: 'line',
      data: {
        labels: ['0'],
        datasets: [
          {
            data: [0],
            borderColor: '#3cba9f',
            fill: true,
            cubicInterpolationMode: 'monotone',
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              display: true,
            },
          ],
        },
      },
    });

    let htmlRefHour = this.hourChartCanvas.nativeElement;
    let hourLabels: any[] = [
      '0:00',
      '1:00',
      '2:00',
      '3:00',
      '4:00',
      '5:00',
      '6:00',
      '7:00',
      '8:00',
      '9:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
    ];

    this.hourChart = new Chart(htmlRefHour, {
      type: 'bar',
      data: {
        labels: hourLabels,
        datasets: [
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#3cba9f',
            fill: false,
          },
        ],
      }, //sdf
      options: {
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              display: true,
            },
          ],
        },
      },
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.updateCharts(null);
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private unsubscribe() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onSelectedCompChanged($event, box) {
    box.selectedComparatorIndex = $event;
    box.getComparator(this.startDate, this.endDate);

    this.writeThresholdObjToStorage(box);
  }

  initUserStatElements() {
    let greaterComparator = {
      name: 'rot',
      compare: function (value: number, threshold: number) {
        return value > threshold;
      },
    };
    let smallerComparator = {
      name: 'grün',
      compare: function (value: number, threshold: number) {
        return value <= threshold;
      },
    };

    let comparatorArray: any[] = [];
    comparatorArray.push(greaterComparator);
    comparatorArray.push(smallerComparator);

    this.navStarts = new UserStatElement();
    this.navStarts.name = 'Navigationsstarts';
    this.navStarts.value = null;
    this.navStarts.threshold = 5;
    this.navStarts.comparators = comparatorArray;
    this.navStarts.selectedComparatorIndex = 0;
    this.navStarts.getComparator(this.startDate, this.endDate);
    this.readThresholdObjFromStorage(this.navStarts);

    this.destNotFound = new UserStatElement();
    this.destNotFound.name = 'nicht erreichte Navigationsziele';
    this.destNotFound.value = null;
    this.destNotFound.threshold = 5;
    this.destNotFound.comparators = comparatorArray;
    this.destNotFound.selectedComparatorIndex = 0;
    this.destNotFound.getComparator(this.startDate, this.endDate);
    this.readThresholdObjFromStorage(this.destNotFound);

    this.addToCart = new UserStatElement();
    this.addToCart.name = 'eingegebene Ziele';
    this.addToCart.value = null;
    this.addToCart.threshold = 5;
    this.addToCart.comparators = comparatorArray;
    this.addToCart.selectedComparatorIndex = 0;
    this.addToCart.getComparator(this.startDate, this.endDate);
    this.readThresholdObjFromStorage(this.addToCart);

    this.usageTime = new UserStatElement();
    this.usageTime.name = 'Nutzungsdauer (h)';
    this.usageTime.value = null;
    this.usageTime.threshold = 5;
    this.usageTime.comparators = comparatorArray;
    this.usageTime.selectedComparatorIndex = 0;
    this.usageTime.getComparator(this.startDate, this.endDate);
    this.readThresholdObjFromStorage(this.usageTime);

    this.navTime = new UserStatElement();
    this.navTime.name = 'Navigationsdauer (h)';
    this.navTime.value = null;
    this.navTime.threshold = 5;
    this.navTime.comparators = comparatorArray;
    this.navTime.selectedComparatorIndex = 0;
    this.navTime.getComparator(this.startDate, this.endDate);
    this.readThresholdObjFromStorage(this.navTime);

    this.uniqUsers = new UserStatElement();
    this.uniqUsers.name = 'Anzahl Nutzer';
    this.uniqUsers.value = null;
    this.uniqUsers.threshold = 5;
    this.uniqUsers.comparators = comparatorArray;
    this.uniqUsers.selectedComparatorIndex = 0;
    this.uniqUsers.getComparator(this.startDate, this.endDate);
    this.readThresholdObjFromStorage(this.uniqUsers);

    if (this.isAdminUser()) {
      this.statBoxes.push(this.uniqUsers);
    }

    this.statBoxes.push(this.navStarts);
    this.statBoxes.push(this.addToCart);
    this.statBoxes.push(this.destNotFound);

    if (this.isAdminUser()) {
      this.statBoxes.push(this.usageTime);
    }

    if (this.isAdminUser()) {
      this.statBoxes.push(this.navTime);
    }
  }

  resetUserStatElemetValues() {
    this.navStarts.value = null;
    this.destNotFound.value = null;
    this.addToCart.value = null;
    this.usageTime.value = null;
    this.navTime.value = null;
    this.uniqUsers.value = null;
    this.top5Users = null;
    this.notFoundDestinations = null;

    this.readThresholdObjFromStorage(this.navStarts);
    this.readThresholdObjFromStorage(this.destNotFound);
    this.readThresholdObjFromStorage(this.addToCart);
    this.readThresholdObjFromStorage(this.usageTime);
    this.readThresholdObjFromStorage(this.navTime);
    this.readThresholdObjFromStorage(this.uniqUsers);
  }

  buildQueryObj(): any {
    switch (this.selectedFilterObj.id) {
      case 0: // all
        return {};
        break;

      case 1: // app only
        return {
          $or: [
            // quick fix: determine app implicitely im Ausschlussverfahren
            { isWzTerminal: null, isIndoorMaps: { $exists: false } },
            { isWzTerminal: false, isIndoorMaps: { $exists: false } },
            { isWzTerminal: { $exists: false }, isIndoorMaps: { $exists: false } },
            // quick fix end
            { isNativeApp: true },
          ],
        };
        break;

      case 2: // terminals only
        return {
          isWzTerminal: true,
        };
        break;

      case 3: // indoor-maps only
        return {
          isIndoorMaps: true,
        };
        break;

      default:
        return {};
    }
  }

  setDailyStatBoxNumbers(box: UserStatElement, results: DBResult[], dataScalingDivider: number = 1) {
    if (results && results.length > 0) {
      let earliest = results
        .map((res) => new Date(res.timestamp))
        .reduce(function (pre, cur) {
          return pre > cur ? cur : pre;
        });
      let latest = results
        .map((res) => new Date(res.timestamp))
        .reduce(function (pre, cur) {
          return pre < cur ? cur : pre;
        });

      let days: Date[];

      if (this.isEqualDates(earliest, latest)) {
        days = [earliest];
      } else {
        days = this.getDatesBetween(earliest, latest);
      }

      let data: any[] = [];
      data.push([]); // diskrete Werte
      data.push([]); // kumulierte Werte

      let sum: number = 0;
      days.forEach((day) => {
        let resultsForThisDay = results.filter((res) => {
          let resDate = new Date(res.timestamp);
          return this.isEqualDates(resDate, day);
        });
        let result: number = resultsForThisDay.length / dataScalingDivider;
        sum += result;
        data[0].push(_.round(result, 4));
        data[1].push(_.round(sum, 4));
      });

      let dayStringLabels: string[] = days.map(
        (d) =>
          d.getDate().toString() + '.' + (d.getMonth() + 1).toString() + '.' + d.getFullYear().toString().substr(-2)
      );
      if (dayStringLabels.length == 0) {
        dayStringLabels = ['0'];
      }
      if (data.length == 0) {
        data = [0];
      }
      box.dailyLabels = dayStringLabels;
      box.dailyData = data;
    } else {
      box.dailyLabels = ['keine'];
      box.dailyData = [0];
    }
  }

  setHourlyStatBoxNumbers(box: UserStatElement, results: DBResult[], dataScalingDivider: number = 1) {
    if (results && results.length > 0) {
      let resultsPerHour: any[] = [];
      resultsPerHour.push([]); // alle Wochentage
      resultsPerHour.push([]); // Sonntag
      resultsPerHour.push([]); // Montag
      resultsPerHour.push([]);
      resultsPerHour.push([]);
      resultsPerHour.push([]);
      resultsPerHour.push([]);
      resultsPerHour.push([]); // Samstag

      for (let i = 0; i < 24; i++) {
        // allgemeinen Bucket füllen
        let resultsForThisHour = results.filter((res) => {
          let resDate: Date = new Date(res.timestamp);
          return resDate.getHours() == i;
        });

        if (resultsForThisHour && resultsForThisHour.length > 0) {
          resultsPerHour[0].push(_.round(resultsForThisHour.length / dataScalingDivider, 4));
        } else {
          resultsPerHour[0].push(0);
        }
      }

      // Buckets nach Wochentagen gefiltert füllen
      for (let tag = 0; tag < 7; tag++) {
        for (let i = 0; i < 24; i++) {
          let resultsForThisHourAndTag = results.filter((res) => {
            let resDate: Date = new Date(res.timestamp);
            let currentHour = resDate.getHours();
            let currentWeekday = resDate.getDay();
            return currentHour === i && currentWeekday === tag;
          });

          let arrIndex: number = tag + 1;

          if (resultsForThisHourAndTag && resultsForThisHourAndTag.length > 0) {
            resultsPerHour[arrIndex].push(_.round(resultsForThisHourAndTag.length / dataScalingDivider, 4));
          } else {
            resultsPerHour[arrIndex].push(0);
          }
        }
      }

      box.hourlyData = resultsPerHour;
    } else {
      box.hourlyData = [[0], [0], [0], [0], [0], [0], [0], [0]];
    }
  }

  getDatesBetween(earliest: Date, latest: Date): Date[] {
    let results: Date[] = [];

    if (earliest > latest) {
      return results;
    }

    let currentDate = earliest;

    while (this.isEqualDates(currentDate, latest) === false) {
      results.push(_.cloneDeep(currentDate));
      currentDate = this.addDays(currentDate, 1);
    }
    results.push(latest);

    return results;
  }

  addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  isEqualHours(d1: Date, d2: Date): boolean {
    return d1.getHours() == d2.getHours();
  }

  isEqualDates(d1: Date, d2: Date): boolean {
    return d1.getFullYear() == d2.getFullYear() && d1.getMonth() == d2.getMonth() && d1.getDate() == d2.getDate();
  }

  setChartData(box: UserStatElement, results: DBResult[], dataScalingDivider: number = 1) {
    this.setDailyStatBoxNumbers(box, results, dataScalingDivider);
    this.setHourlyStatBoxNumbers(box, results, dataScalingDivider);

    if (box.isExtended === true || this.currentlySelectedElement === box) {
      this.updateCharts(box);
    }
  }

  setChartsTitle(newTitle: string) {
    let titleObjDaily = {
      display: true,
      text: newTitle + ' nach Datum',
    };
    let titleObjHourly = {
      display: true,
      text: newTitle + ' nach Uhrzeit',
    };

    this.dayChart.options.title = titleObjDaily;
    this.hourChart.options.title = titleObjHourly;
  }

  handleNavStartData(results: DBResult[]) {
    if (results && results.length) {
      this.navStarts.value = results.length;
    } else {
      this.navStarts.value = 0;
    }

    this.setChartData(this.navStarts, results);
    this.navStarts.getComparator(this.startDate, this.endDate);
  }

  handleDestFoundData(results: DBResult[]) {
    let notFoundList = results.filter((item) => {
      let payload: LogDataTypes.destFound = item.data.destinationFound;
      let isDemo: boolean = item.isDemoInProgress == true;
      return !payload.found && !isDemo;
    });

    if (notFoundList && notFoundList.length) {
      this.destNotFound.value = notFoundList.length;
    } else {
      this.destNotFound.value = 0;
    }

    this.notFoundDestinations = notFoundList;
    this.setChartData(this.destNotFound, notFoundList);
    this.destNotFound.getComparator(this.startDate, this.endDate);
  }

  handleAddToCartData(results: DBResult[]) {
    if (results && results.length) {
      this.addToCart.value = results.length;
    } else {
      this.addToCart.value = 0;
    }

    this.setChartData(this.addToCart, results);
    this.addToCart.getComparator(this.startDate, this.endDate);

    // destination frequency/dest-count
    this.destsCountData = this.evaluateDestFreq(results);
    this.sortDestFreqTable('count', SortOrder.desc);
  }

  private evaluateDestFreq(results: DBResult[]) {
    // selectors
    const selectDestTxt = (itm) => (itm && itm.data && itm.data.add2cart ? itm.data.add2cart.item : undefined);
    const selectSearchQuery = (itm) => (itm && itm.data && itm.data.add2cart ? itm.data.add2cart.query : undefined);
    const selectUserId = (itm) => (itm && itm.device ? itm.device.uuid : undefined);
    const selectTimestamp = (itm) => (itm ? itm.timestamp : undefined);

    // operate
    const destAddedRecs = results;
    const allUniqDestTxts = fp.pipe(fp.map(selectDestTxt), fp.uniq)(destAddedRecs);
    const recsByDestTxt = _.groupBy(destAddedRecs, selectDestTxt);
    const countByDestTxt = _.countBy(destAddedRecs, selectDestTxt);
    const userIdsByDestTxt = _.mapValues(
      recsByDestTxt,
      fp.pipe(
        fp.map(selectUserId),
        fp.map((val) => val || 'n/a')
      )
    );

    const searchQueriesByDestTxt = _.mapValues(
      recsByDestTxt,
      fp.pipe(
        fp.map(selectSearchQuery),
        fp.map((val) => val || 'n/a')
      )
    );
    const lastSearchedTimestampByDestTxt = _.mapValues(recsByDestTxt, fp.pipe(fp.map(selectTimestamp), fp.max));

    //    build records
    const destFreqData = _.map(allUniqDestTxts, (destTxt) => {
      const latestTimestamp = lastSearchedTimestampByDestTxt[destTxt];
      return {
        destTxt,
        count: countByDestTxt[destTxt],
        latestTimestamp,
        latestTimestampReadable: new Date(latestTimestamp).toLocaleString('de-DE'),
        userIds: userIdsByDestTxt[destTxt],
        searchQueries: searchQueriesByDestTxt[destTxt],
      };
    });
    return destFreqData;
  }

  handlePosUpdateData(results: DBResult[]) {
    let navRunning = results.filter((resultObj) => {
      return (resultObj.data.positionUpdate as LogDataTypes.positionUpdate).isNavRunning;
    });

    if (navRunning && navRunning.length) {
      this.navTime.value = _.round(navRunning.length / (60 * 60), 2);
    } else {
      this.navTime.value = 0;
    }

    this.setChartData(this.navTime, navRunning, 60 * 60);
    this.navTime.getComparator(this.startDate, this.endDate);
  }

  handleSearchTermData(results: DBResult[]) {
    this.getSearchTerms(results);
  }

  handleNavDestData(results: DBResult[]) {
    this.getNavDestData(results);
  }

  handleCombinedData(results: DBResult[]) {
    // hier sachen tun für die man alle daten kombiniert braucht, zb user rausfinden!

    if (results && results.length) {
      this.usageTime.value = _.round(results.length / (60 * 60), 2);
    } else {
      this.usageTime.value = 0;
    }

    this.setChartData(this.usageTime, results, 60 * 60);
    this.usageTime.getComparator(this.startDate, this.endDate);

    let uniqRanked = this.getUniqueUsersRanked(results);
    this.getTopUsers(uniqRanked);

    let uniqueDeviceIdResults = _.uniqBy(results, (x) => {
      return (x as DBResult).device.uuid || 'unknown';
    });

    if (uniqueDeviceIdResults && uniqueDeviceIdResults.length) {
      this.uniqUsers.value = uniqueDeviceIdResults.length;
    } else {
      this.uniqUsers.value = 0;
    }

    this.setChartData(this.uniqUsers, uniqueDeviceIdResults);
    this.uniqUsers.getComparator(this.startDate, this.endDate);
  }

  reloadData() {
    this.unsubscribe();
    this.resetUserStatElemetValues();
    this.subscriptions = [];

    const queryFilterObj: any = this.buildQueryObj();

    const obs1$ = this.dataService
      .fetchDataForCurrentMap(DBCollection.navStart, queryFilterObj, {}, false, this.startDate, this.endDate)
      .pipe(
        tap((results) => {
          this.handleNavStartData(results);
        })
      );

    let obs2$ = this.dataService
      .fetchDataForCurrentMap(DBCollection.destinationFound, queryFilterObj, {}, false, this.startDate, this.endDate)
      .pipe(
        tap((results) => {
          this.handleDestFoundData(results);
        })
      );

    const obs3$ = this.dataService
      .fetchDataForCurrentMap(DBCollection.addToCart, queryFilterObj, {}, false, this.startDate, this.endDate)
      .pipe(
        tap((results) => {
          this.handleAddToCartData(results);
        })
      );

    let obs4$ = this.dataService
      .fetchDataForCurrentMap(DBCollection.positionUpdate, queryFilterObj, {}, false, this.startDate, this.endDate)
      .pipe(
        tap((results) => {
          this.handlePosUpdateData(results);
        })
      );

    let obs5$ = this.dataService
      .fetchDataForCurrentMap(DBCollection.searchTerm, queryFilterObj, {}, false, this.startDate, this.endDate)
      .pipe(
        tap((results) => {
          this.handleSearchTermData(results);
        })
      );

    // let obs6$ = this.dataService
    //   .fetchDataForCurrentMap(DBCollection.searchTerm, queryFilterObj, {}, false, this.startDate, this.endDate)
    //   .pipe(
    //     tap((results) => {
    //       this.handleNavDestData(results);
    //     })
    //   );

    let allSubscriptions = forkJoin(obs1$, obs2$, obs3$, obs4$, obs5$).subscribe(
      ([navStartResults, destFoundResults, addCartResults, posUpdResults, searchTResults]) => {
        let allResultsCombined: DBResult[] = [
          ...navStartResults,
          ...destFoundResults,
          ...addCartResults,
          ...posUpdResults,
          ...searchTResults,
        ];
        this.handleCombinedData(allResultsCombined);
      }
    );

    this.subscriptions.push(allSubscriptions);
  }

  getNavDestData(results: DBResult[]) {
    // this.suchbegriffe = [];
    let resultArray = [];

    results.forEach((res) => {
      let userId = res.device.uuid;
      let query = res.data.searchQueryEntered.query;
      let results = res.data.searchQueryEntered.results;
      let timestamp = res.timestamp;

      let returnObj: any = {
        userId: userId,
        timestamp: timestamp,
      };

      if (results && results.length && results.length > 0) {
        returnObj.results = results.map((x) => x.title);
      }

      if (query.length && query.length > 0) {
        returnObj.query = query;
        resultArray.push(returnObj);
      }
    });

    // this.suchbegriffe = this.getTopSuchbegriffe(resultArray);
  }

  getSearchTerms(results: DBResult[]) {
    this.suchbegriffe = [];
    let resultArray = [];

    results.forEach((res) => {
      let userId = res.device.uuid;
      let query = res.data.searchQueryEntered.query;
      let results = res.data.searchQueryEntered.results;
      let timestamp = res.timestamp;

      let returnObj: any = {
        userId: userId,
        timestamp: timestamp,
      };

      if (results && results.length && results.length > 0) {
        returnObj.results = results.map((x) => x.title);
      }

      if (query.length && query.length > 0) {
        returnObj.query = query;
        resultArray.push(returnObj);
      }
    });

    this.suchbegriffe = this.getTopSuchbegriffe(resultArray);
  }

  getTopSuchbegriffe(arr): any[] {
    let noStupidArray: any[] = this.removeFaultySearches(arr);
    let uniqArr: any[] = _.uniqBy(noStupidArray, function (x) {
      return x.query.toLowerCase();
    });

    let rankedArr: any[] = [];

    uniqArr.forEach((item) => {
      let occurancesArr = arr.filter((x) => x.query.toLowerCase() == item.query.toLowerCase());

      let uniqUserIDsArr = _.uniq(
        occurancesArr.map((item) => {
          return item.userId;
        })
      );
      item.userIds = uniqUserIDsArr;

      occurancesArr = _.sortBy(occurancesArr, 'timestamp');
      let lastSearchedTimestamp: number = (<any>_.last(occurancesArr)).timestamp;
      item.lastSearchedTimestamp = lastSearchedTimestamp;
      item.lastSearchedTimestampReadable = new Date(lastSearchedTimestamp).toLocaleString('de-DE');

      if (occurancesArr && occurancesArr.length) {
        item.occurances = occurancesArr.length;
        rankedArr.push(item);
      }
    });

    let sortedByRankArr = _.orderBy(rankedArr, ['occurances'], ['desc']);
    //console.warn("sortedByRankArr", sortedByRankArr);

    return _.cloneDeep(sortedByRankArr);
  }

  removeFaultySearches(results: any[]): any[] {
    let resultsLong: any[] = results.filter((item) => {
      return item.query.length > 1;
    });

    let cleanedResults: any[] = [];
    resultsLong.forEach((item, index) => {
      cleanedResults.push(item);
      if (index > 0) {
        let lastItem: any = results[index - 1];
        let timeDiff: number = Math.abs(item.timestamp - lastItem.timestamp);
        let oneMinute: number = 60 * 1000;

        if (item.query.startsWith(lastItem.query) && item.userId === lastItem.userId && timeDiff < oneMinute) {
          //console.warn(lastItem, item);
          // letztes Element war nur Teil des aktuellen, also löschen wir das letzte
          cleanedResults = cleanedResults.filter((x) => {
            return x !== lastItem;
          });
        }
      }
    });
    console.info(
      '[removeFaultySearches]',
      results.length.toString() + ' zu ' + cleanedResults.length.toString() + ' Suchanfragen kombiniert'
    );
    return cleanedResults;
  }

  translatePhoneModels(manufacturer: string, model: string): string {
    let phoneDictionary = {
      'iPhone5,1': 'iPhone 5 (GSM)',
      'iPhone5,2': 'iPhone 5 (GSM+CDMA)',
      'iPhone5,3': 'iPhone 5C (GSM)',
      'iPhone5,4': 'iPhone 5C (Global)',
      'iPhone6,1': 'iPhone 5S (GSM)',
      'iPhone6,2': 'iPhone 5S (Global)',
      'iPhone7,1': 'iPhone 6 Plus',
      'iPhone7,2': 'iPhone 6',
      'iPhone8,1': 'iPhone 6s',
      'iPhone8,2': 'iPhone 6s Plus',
      'iPhone8,3': 'iPhone SE (GSM+CDMA)',
      'iPhone8,4': 'iPhone SE (GSM)',
      'iPhone9,1': 'iPhone 7',
      'iPhone9,2': 'iPhone iPhone 7 Plus',
      'iPhone9,3': 'iPhone 7',
      'iPhone9,4': 'iPhone 7 Plus',
      'iPhone10,1': 'iPhone 8',
      'iPhone10,2': 'iPhone 8 Plus',
      'iPhone10,3': 'iPhone X Global',
      'iPhone10,4': 'iPhone 8',
      'iPhone10,5': 'iPhone 8 Plus',
      'iPhone10,6': 'iPhone X (GSM)',
      'iPhone11,2': 'iPhone XS',
      'iPhone11,4': 'iPhone XS Max (China)',
      'iPhone11,6': 'iPhone XS Max',
      'iPhone11,8': 'iPhone XR',
      'iPod7,1': 'iPod touch 6.Gen (2015)',

      'SM-N9005': 'Samsung Galaxy Note 3',
      'SM-A320FL': 'Samsung Galaxy A3 (2017)',
      'GT-I9505': 'Samsung Galaxy S4',
      'SM-G900F': 'Samsung Galaxy S5',
      'SM-G901F': 'Samsung Galaxy S5 Plus',
      'SM-G903F': 'Samsung Galaxy S5 Neo',
      'SM-A520F': 'Samsung Galaxy A5 (2017)',
      'SM-J530F': 'Samsung Galaxy J5 (2017)',
      'SM-A600FN': 'Samsung Galaxy A6',
      'SM-G925F': 'Samsung Galaxy S6 edge',
      'SM-J730F': 'Samsung Galaxy J7 (2017)',
      'SM-G930F': 'Samsung Galaxy S7',
      'SM-G935F': 'Samsung Galaxy S7 edge',
      'SM-N950F': 'Samsung Galaxy Note 8',
      'SM-G950F': 'Samsung Galaxy S8',
      'SM-G955F': 'Samsung Galaxy S8+',
      'SM-N960F': 'Samsung Galaxy Note 9',
      'SM-G960F': 'Samsung Galaxy S9',
      'SM-G965F': 'Samsung Galaxy S9+',

      'PRA-LX1': 'Huawei P8 Lite',
      'BND-L21': 'Huawei Honor 7X',
      'FRD-L09': 'Huawei Honor 8',
      'LLD-L31': 'Huawei Honor 9 Lite',
      'COL-L29': 'Huawei Honor 10',
      'HUAWEI GRA-L09': 'Huawei P8',
      'WAS-LX1A': 'Huawei P10 Lite',
      'VTR-L09': 'Huawei P10',
      'FIG-LX1': 'Huawei P smart',
      'BLA-L29': 'Huawei Mate 10 Pro',
      'SNE-LX1': 'Huawei Mate 20 Lite',
      'ANE-LX1': 'Huawei P20 Lite',
      'EML-L29': 'Huawei P20',
      'CLT-L29': 'Huawei P20 Pro',

      'HTC U11': 'HTC U11',
      'HTC U12+': 'HTC U12+',

      'ONEPLUS A3003': 'OnePlus 3/3T',
      'ONEPLUS A5000': 'OnePlus 5 A5000',
      'ONEPLUS A5010': 'OnePlus 5T',
      'ONEPLUS A6003': 'OnePlus 6',

      'LG-H850': 'LG G5',

      'XT1635-02': 'Motorola Moto Z Play',

      F5121: 'Sony Xperia X',
      D5803: 'Sony Xperia Z3 Compact',
      H8296: 'Sony Xperia XZ2',
      E5823: 'Sony Xperia Z5 Compact',
    };

    if (phoneDictionary[model]) {
      return phoneDictionary[model];
    } else {
      return manufacturer + ' ' + model;
    }
  }

  getTopUsers(sortedArray: any[]) {
    //console.error("resultArray", sortedArray);

    let top5Objects: any[] = _.cloneDeep(sortedArray);
    let resultArr: any[] = [];
    top5Objects.forEach((item) => {
      let newResult = {
        name: '',
        userId: '',
        amount: '',
      };
      let dbr: DBResult = _.head(item.logs) as DBResult;
      newResult.name = this.translatePhoneModels(dbr.device.manufacturer, dbr.device.model);
      newResult.userId = dbr.device.uuid;
      newResult.amount = _.round(item.counter / 60, 1).toString() + ' Minuten';
      resultArr.push(newResult);
    });

    this.top5Users = resultArr;
  }

  getUniqueUsersRanked(resultArray: DBResult[]): any[] {
    let uniqArr: any = {};

    resultArray.forEach((val) => {
      let id: string = val.device.uuid || null;
      if (id) {
        if (uniqArr[id]) {
          uniqArr[id].counter += 1;
          uniqArr[id].logs.push(val);
        } else {
          uniqArr[id] = {};
          uniqArr[id].logs = [];
          uniqArr[id].logs.push(val);
          uniqArr[id].counter = 1;
        }
      }
    });

    let returnArr: any[] = [];
    _.forOwn(uniqArr, (item) => returnArr.push(item));
    returnArr = _.orderBy(returnArr, ['counter'], ['desc']);

    return returnArr;
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if (event.beginJsDate && event.endJsDate) {
      this.startDate = event.beginJsDate;
      this.endDate = event.endJsDate;
      this.closeDiagrams();
      this.reloadData();

      let msgObj = {
        type: 'dateRangeChange',
        data: {
          start: event.beginJsDate,
          end: event.endJsDate,
        },
      };

      this.msgService.announceMission(JSON.stringify(msgObj));
    }
  }

  onThresholdChanged($event, objectChanged) {
    objectChanged.threshold = $event.target.value;
    objectChanged.getComparator(this.startDate, this.endDate);

    this.writeThresholdObjToStorage(objectChanged);
  }

  public toggleSettings() {
    this.settingsOpen = !this.settingsOpen;
  }

  selectUserId(userId) {
    if (userId) {
      let msgObj = {
        type: 'selectUserId',
        data: userId,
      };

      this.msgService.announceMission(JSON.stringify(msgObj));
    }
  }

  writeThresholdObjToStorage(obj) {
    this.storage.set(obj.name + this.currentMap, obj);
  }

  readThresholdObjFromStorage(obj: UserStatElement) {
    let memObj: UserStatElement = this.storage.get(obj.name + this.currentMap);

    if (memObj) {
      obj.selectedComparatorIndex = memObj.selectedComparatorIndex;
      obj.threshold = memObj.threshold;
    } else {
      //default values
      obj.selectedComparatorIndex = 0;
      obj.threshold = 5;
    }
  }

  closeDiagrams() {
    if (this.currentlySelectedElement != null) {
      this.currentlySelectedElement.isExtended = false;
      this.currentlySelectedElement = null;
    }
  }

  onSelectedFilterChanged(event) {
    this.closeDiagrams();
    this.selectedFilterObj = event;
    this.reloadData();
  }

  isAdminUser = () => this.currentUserName === 'admin';

  statBoxOnClick(event: UserStatElement) {
    if (this.currentlySelectedElement == event) {
      this.currentlySelectedElement.isExtended = false;
      this.currentlySelectedElement = null;
    } else {
      if (this.currentlySelectedElement != null) {
        this.currentlySelectedElement.isExtended = false;
      }
      event.isExtended = true;
      this.currentlySelectedElement = event;
      this.setChartsTitle(event.name);
      this.updateCharts(event);
    }
  }

  suchvorschlagHeaderOnClick() {
    if (this.suchbegriffe && this.suchbegriffe.length > 0) {
      let sortMode: any = 'asc';
      if (this.suchbegriffeSorting.resultsSortedAsc) {
        sortMode = 'asc';
      } else {
        sortMode = 'desc';
      }

      let secondarySortMode: any = 'asc';
      if (this.suchbegriffeSorting.occurancesSortedAsc) {
        secondarySortMode = 'asc';
      } else {
        secondarySortMode = 'desc';
      }

      this.suchbegriffeSorting.resultsSortedAsc = !this.suchbegriffeSorting.resultsSortedAsc;
      this.suchbegriffeSorting.lastSearchedSortedAsc = null;

      this.suchbegriffe = _.orderBy(
        this.suchbegriffe,
        [
          function (e) {
            if (e.results && e.results.length) {
              return e.results.length;
            } else {
              return 0;
            }
          },
          'occurances',
          function (e) {
            return e.query.length;
          },
        ],
        [sortMode, secondarySortMode, 'desc']
      );
    }
  }

  suchbegriffHeaderOnClick() {
    if (this.suchbegriffe && this.suchbegriffe.length > 0) {
      let sortMode: any = 'asc';
      if (this.suchbegriffeSorting.occurancesSortedAsc) {
        sortMode = 'asc';
      } else {
        sortMode = 'desc';
      }

      let secondarySortMode: any = 'asc';
      if (this.suchbegriffeSorting.resultsSortedAsc) {
        secondarySortMode = 'asc';
      } else {
        secondarySortMode = 'desc';
      }

      this.suchbegriffeSorting.occurancesSortedAsc = !this.suchbegriffeSorting.occurancesSortedAsc;
      this.suchbegriffeSorting.lastSearchedSortedAsc = null;

      this.suchbegriffe = _.orderBy(
        this.suchbegriffe,
        [
          'occurances',
          function (e) {
            if (e.results && e.results.length) {
              return e.results.length;
            } else {
              return 0;
            }
          },
        ],
        [sortMode, secondarySortMode]
      );
    }
  }

  onClickDestFreqSort(sortProperty) {
    this.sortDestFreqTable(sortProperty);
  }

  private sortDestFreqTable(sortProperty, sortOrder?: SortOrder) {
    this.destsCountDataSortProperty = sortProperty;

    if (sortOrder) {
      this.destsCountDataSortOrder = sortOrder;
    } else {
      this.destsCountDataSortOrder = this.destsCountDataSortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
    }

    this.destsCountData = _.orderBy(this.destsCountData, [(e) => e[this.destsCountDataSortProperty]], <any>[
      this.destsCountDataSortOrder,
    ]);
  }

  zuletztGesuchtHeaderOnClick() {
    if (this.suchbegriffe && this.suchbegriffe.length > 0) {
      let sortMode: any = 'asc';
      if (this.suchbegriffeSorting.lastSearchedSortedAsc) {
        sortMode = 'asc';
      } else {
        sortMode = 'desc';
      }

      this.suchbegriffeSorting.lastSearchedSortedAsc = !this.suchbegriffeSorting.lastSearchedSortedAsc;
      this.suchbegriffeSorting.occurancesSortedAsc = null;
      this.suchbegriffeSorting.resultsSortedAsc = null;

      this.suchbegriffe = _.orderBy(
        this.suchbegriffe,
        [
          function (e) {
            return e.lastSearchedTimestamp;
          },
        ],
        [sortMode]
      );
    }
  }

  suchbegriffeContainerResizeOnClick() {
    this.isSuchbegriffeContainerLarge = !this.isSuchbegriffeContainerLarge;
  }

  onSelectedHourlyFilterChanged(id) {
    this.hourlyChartSelectedFilter = id;
    this.updateChartsData(this.currentlySelectedElement);
  }

  onSelectedDailyFilterChanged(id) {
    this.dailyChartSelectedFilter = id;
    this.updateCharts(this.currentlySelectedElement);
  }
}
