import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-main-content',
  templateUrl: './dashboard-main-content.component.html',
  styleUrls: ['./dashboard-main-content.component.scss'],
})
export class DashboardMainContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
