import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { User, UserAccountService } from '../../user-account.service';
import { DataHubService } from '../../server-connection/data-hub.service';
import { MapsService } from '../../maps.service';
import { Map } from '../../server-connection/statistics-server.service';

@Component({
  selector: 'wz-portal-hub',
  templateUrl: './wz-portal-hub.component.html',
  styleUrls: ['./wz-portal-hub.component.sass'],
})
export class WzPortalHubComponent implements OnInit {
  urlUsername: string;
  user: User = new User();
  failedLoginAttempt: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private accountService: UserAccountService,
    private router: Router,
    private dataHubService: DataHubService,
    private mapsService: MapsService
  ) {
    this.accountService.currentUser$
      // .pipe(
      //     takeUntil(componentDestroyed(this))
      // )
      .subscribe((usr) => {
        if (!usr) {
          console.log('not logged in! Redirecting..');
          this.router.navigate(['login']);
        } else {
          console.log('logged in as', usr.username, usr);
          console.log('user', usr);
        }
      });
  }

  ngOnInit() {
    const id: string = this.route.snapshot.paramMap.get('id');

    if (id !== null) {
      this.urlUsername = id;
      this.user.username = id;
    }
  }

  async logInButtonClicked() {
    let didSuccessfullyLogin = await this.accountService.logInUser(this.user.username, this.user.password);
    if (didSuccessfullyLogin) {
      console.log('account details correct.');
      this.failedLoginAttempt = false;
      this.router.navigate(['dashboard']);
    } else {
      console.log('wrong account details');
      this.failedLoginAttempt = true;
    }
  }

  openNeonMaps() {
    this.mapsService.getListOfServerMaps().subscribe((mapsList) => {
      try {
        const selectedMapTitle = this.getMapTitleForNeonMaps();
        const mapRec: any = _.find(mapsList, { title: selectedMapTitle });

        if (mapRec) {
          const mapId = mapRec._id; // helios
          const token = this.accountService.getCurrentToken();
          const location =
            window.location.protocol + '//' + window.location.host + '/neon/login?token=' + token + '&map-id=' + mapId;
          window.location = <any>location;
        } else {
          console.log('maps-list-response does not include map-title requested');
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  getMapTitleForNeonMaps(): any {
    const activeMapTitle = this.dataHubService.getCurrentMap();
    if (activeMapTitle) {
      return activeMapTitle;
    }

    const mapTitles = this.accountService.getCurrentMapTitles();
    if (mapTitles && mapTitles.length === 1) {
      return mapTitles[0];
    }

    const hasAccessToUka = mapTitles && mapTitles.includes(Map.uniklinikAachen);
    if (hasAccessToUka) {
      return Map.uniklinikAachen;
    }

    return _.sample(mapTitles);
  }
}
