import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiModule } from './ui/ui.module';
import { ServerConnectionModule } from './server-connection/server-connection.module';
import { PluginsModule } from './plugins/plugins.module';

import { StorageServiceModule } from 'ngx-webstorage-service';
import { WhatsNextComponent } from './whats-next/whats-next.component';

// hack - make sure that jQuery plugins can find
//        jquery reference
//import * as $ from 'jquery';
//window["$"] = $;
//window["jQuery"] = $;

@NgModule({
  declarations: [AppComponent, WhatsNextComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    UiModule,
    ServerConnectionModule,
    HttpClientModule,
    StorageServiceModule,
    FormsModule,
    PluginsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
