import { Component, OnInit } from '@angular/core';
import { UserAccountService } from '../../user-account.service';
import { Router } from '@angular/router';
import { MessageService } from '../../message.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService],
})
export class DashboardComponent implements OnInit {
  constructor(private accountService: UserAccountService, private router: Router) {
    // let usr = accountService.getCurrentUser();
    // if (!usr) {
    //   console.log("not logged in! Redirecting..");
    //   this.router.navigate(["login"]);
    // } else {
    //   console.log("logged in as", usr.username);
    // }

    this.accountService.currentUser$
      // .pipe(
      //     takeUntil(componentDestroyed(this))
      // )
      .subscribe((usr) => {
        if (!usr) {
          console.log('not logged in! Redirecting..');
          this.router.navigate(['login']);
        } else {
          console.log('logged in as', usr.username);
        }
      });
  }

  ngOnInit() {}
}
