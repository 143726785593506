import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DataHubService } from './server-connection/data-hub.service';
import { BehaviorSubject, Observable, Subject, ReplaySubject, of } from 'rxjs';
import { Map } from './server-connection/statistics-server.service';
import { HttpClient } from '@angular/common/http';
import { catchError, debounceTime, map, retry } from 'rxjs/operators';
import { authEndpointUrl } from './server-connection/server-endpoints';

export class User {
  username: string;
  password: string;
  maps: Map[];
  mapTitles: string[];
  mapsData: [{}];
}

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  users: User[] = [];
  public currentUser$: ReplaySubject<User> = new ReplaySubject<User>(1);

  // private authEndpointUrl: string = "http://192.168.178.74:1716/auth";
  // private authEndpointUrl = 'http://localhost:1717/auth';

  constructor(
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private dataService: DataHubService,
    private http: HttpClient
  ) {
    // const admin: User = new User();
    // admin.username = 'admin';
    // admin.password = '';
    // admin.maps = [
    //   Map.digitalChurch,
    //   Map.edekaHatle,
    //   Map.eurogressAachen,
    //   Map.luisenhospitalAachen,
    //   Map.marienhospitalAachen,
    //   Map.mobauAachen,
    //   Map.uniklinikAachen,
    //   Map.apagHbf,
    //   Map.kreisverwaltungViersen,
    //   Map.betlehemStolberg,
    //   Map.heliosWuppertal,
    //   Map.rheinKreisNeussStandortGrevenbroich,
    //   Map.rheinKreisNeussStandortNeuss,
    //   Map.StFranziskusMuenster,
    //   Map.alle
    // ];
    // this.users.push(admin);

    // const uka: User = new User();
    // uka.username = 'uka';
    // uka.password = '';
    // uka.maps = [Map.uniklinikAachen];
    // this.users.push(uka);

    // let edeka: User = new User();
    // edeka.username = "edeka";
    // edeka.password = "";
    // edeka.maps = [Map.edekaHatle];
    // this.users.push(edeka);

    const currentUser = this.getCurrentUser();
    this.updateCurrentUser(currentUser);
  }

  logInUser(username: string, password: string): Promise<{}> {
    console.error('logInuser');

    return this.http
      .post(authEndpointUrl, { user: username, pw: password })
      .pipe(
        debounceTime(20),
        retry(2),
        map((obj: { token: string; mapTitles: Array<Map>; maps: [{}] }) => {
          const user = new User();
          user.username = username;
          user.maps = obj.mapTitles;
          user.mapTitles = obj.mapTitles;
          user.mapsData = obj.maps;

          // console.log('MAPPO', obj.mapsData);

          this.storage.set('user', user);
          this.storage.set('token', obj.token);
          this.storage.set('mapTitles', obj.mapTitles);

          // this.dataService.setCurrentMap(obj.mapTitles[0]);

          const defaultMapByTitleStr = Map.uniklinikAachen;
          if (obj.mapTitles.includes(defaultMapByTitleStr)) {
            this.dataService.setCurrentMap(defaultMapByTitleStr);
          } else {
            this.dataService.setCurrentMap(obj.mapTitles[0]);
          }

          this.updateCurrentUser(user);
          return true;
        }),
        catchError((err) => {
          console.log('erro', err);
          this.storage.remove('user');
          this.storage.remove('token');
          this.storage.remove('mapTitles');
          this.updateCurrentUser(null);
          return of(false);
        })
      )
      .toPromise();
  }

  getCurrentUser(): User {
    return this.storage.get('user');
  }

  getCurrentToken(): string {
    return this.storage.get('token');
  }

  getCurrentMapTitles(): string {
    return this.storage.get('mapTitles');
  }

  logOutUser() {
    this.storage.remove('user');
    this.storage.remove('token');
    this.updateCurrentUser(null);
  }

  private updateCurrentUser(newUser: User) {
    this.currentUser$.next(newUser);
  }
}
