import { Component, OnInit } from '@angular/core';
import { UserAccountService } from '../../user-account.service';
import { DataHubService } from '../../server-connection/data-hub.service';
import { MessageService } from '../../message.service';
import { Map } from '../../server-connection/statistics-server.service';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss'],
})
export class DashboardSidebarComponent implements OnInit {
  availableMaps: Map[] = [];
  selectedMap: Map = null;
  public isSidebarExpanded: boolean = true;

  constructor(
    private accountService: UserAccountService,
    private dataService: DataHubService,
    private msgService: MessageService
  ) {}

  ngOnInit() {
    // this.availableMaps = this.accountService.getCurrentUser().maps;
    // if (this.availableMaps) {
    //   this.selectMap(this.availableMaps[0]);
    // }

    this.accountService.currentUser$.subscribe((usr) => {
      if (!usr) {
        return;
      }

      this.availableMaps = usr.maps;

      const defaultMap = this.availableMaps.includes(<Map>'Uniklinik RWTH Aachen')
        ? Map.uniklinikAachen
        : this.availableMaps[0];
      this.selectMap(defaultMap);
    });
  }

  selectMap(map: Map) {
    this.dataService.setCurrentMap(map);
    this.selectedMap = map;

    let msgObj = {
      type: 'map',
      data: map,
    };
    this.msgService.announceMission(JSON.stringify(msgObj));
  }

  expanderBtnOnClick() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }
}
