import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PluginsModule } from '../plugins/plugins.module';

import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardMainContentComponent } from './dashboard-main-content/dashboard-main-content.component';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { LoginComponent } from './login/login.component';
import { WzPortalHubComponent } from './wz-portal-hub/wz-portal-hub.component';
import {HowtosComponent} from './howtos/howtos.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    DashboardComponent,
    DashboardMainContentComponent,
    DashboardSidebarComponent,
    LoginComponent,
    WzPortalHubComponent,
    HowtosComponent
  ],
  imports: [CommonModule, AppRoutingModule, FormsModule, PluginsModule],
  exports: [LayoutComponent],
})
export class UiModule {}
