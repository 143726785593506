import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserAccountService } from '../../user-account.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  username: string = null;
  isLoggedIn: boolean = false;

  constructor(private accountService: UserAccountService) {
    this.accountService.currentUser$.pipe(takeUntil(componentDestroyed(this))).subscribe((user) => {
      if (user) {
        this.username = user.username;
        console.log("user: " + JSON.stringify(user));
      } else {
        this.username = null;
      }
    });
  }

  ngOnInit() {
    let usr = this.accountService.getCurrentUser();

    if (usr) {
      this.username = usr.username;
    } else {
      this.username = null;
    }
  }

  ngOnDestroy() {}

  logOutClicked() {
    this.accountService.logOutUser();
  }
}
