// beware: locally port 1717
// const statisticsServerUrl = "http://localhost:1717/stats";
// const authEndpointUrl = 'http://localhost:1717/auth';

// beware: remote port 1716
// const statisticsServerUrl = "http://192.168.178.74:1716/stats";
// const authEndpointUrl = 'http://192.168.178.74:1716/auth';

// beware: remote port 1716
// const statisticsServerUrl = 'http://192.168.178.130:5010/stats';
// const authEndpointUrl =     'http://192.168.178.130:5010/auth';


const statisticsServerUrl = '/stats';
const authEndpointUrl = '/auth';


export { statisticsServerUrl, authEndpointUrl };
