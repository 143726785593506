var FloorPlanTextureCacheService = function () {
  var _tileCache = {};

  var hasTile = function (mapId, level, x, y) {
    return (
      _tileCache.hasOwnProperty(mapId) &&
      _tileCache[mapId].hasOwnProperty(level) &&
      _tileCache[mapId][level].hasOwnProperty(x) &&
      _tileCache[mapId][level][x].hasOwnProperty(y)
    );
  };

  var clear = function () {
    _tileCache = {};
  };

  var getTile = function (mapId, level, x, y) {
    return _tileCache[mapId][level][x][y];
  };

  var addTile = function (mapId, level, x, y, tileMesh) {
    if (!_tileCache.hasOwnProperty(mapId)) {
      _tileCache[mapId] = {};
    }

    if (!_tileCache[mapId].hasOwnProperty(level)) {
      _tileCache[mapId][level] = {};
    }

    if (!_tileCache[mapId][level].hasOwnProperty(x)) {
      _tileCache[mapId][level][x] = {};
    }

    _tileCache[mapId][level][x][y] = tileMesh;
  };

  return {
    hasTile: hasTile,
    getTile: getTile,
    addTile: addTile,
    clear: clear,
  };
};

const floorPlanTextureCacheService = new FloorPlanTextureCacheService();

export { floorPlanTextureCacheService };
