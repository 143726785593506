import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStatisticsComponent } from './user-statistics/user-statistics.component';
import { FormsModule } from '@angular/forms';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { MovementStatisticsComponent } from './movement-statistics/movement-statistics.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [UserStatisticsComponent, MovementStatisticsComponent],
  imports: [NgbModule, CommonModule, FormsModule, MyDateRangePickerModule, RouterModule],
  exports: [UserStatisticsComponent, MovementStatisticsComponent],
})
export class PluginsModule {}
