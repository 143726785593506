import { Injectable, Inject, OnDestroy } from '@angular/core';
import { DBResult, DBCollection, Map, StatisticsServerService } from './statistics-server.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, AsyncSubject, of } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

export class AllData {
  addToCart: DBResult[];
  destinationFound: DBResult[];
  destinationReached: DBResult[];
  isLevelOffroute: DBResult[];
  navStart: DBResult[];
  positionUpdate: DBResult[];
  searchTerm: DBResult[];
}

export namespace LogDataTypes {
  export interface positionUpdate {
    isGuidedNavMode: boolean;
    isFreeNavMode: boolean;
    isNavRunning: boolean;
    curPosStateless: any;
    curPosStateBased: any;
  }

  export interface destFound {
    found: boolean;
    targetDistanceMeterStateless: number;
    targetDistanceMeterStateBased: number;
    destinationTitle: string;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DataHubService implements OnDestroy {
  useCaching: boolean = true;
  //cache = {};
  pendingRequestSubjects = {};

  constructor(private statisticsService: StatisticsServerService) {}

  ngOnDestroy() {}

  setCurrentMap(map: Map) {
    console.error('setting', map);
    this.statisticsService.setCurrentMap(map);
  }

  getCurrentMap(): Map {
    return this.statisticsService.getCurrentMap();
  }

  private removeUnwantedLogs(resultArray: DBResult[]): DBResult[] {
    // removes logs which are not app version >= 5 or 4.4
    // also removes our test devices

    if (resultArray) {
      return resultArray.filter((item) => {
        let correctVersion: boolean = (item.app.major == 4 && item.app.minor >= 4) || item.app.major > 4;

        if (item.isTestDevice || !correctVersion) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return [];
    }
  }

  fetchDataForCurrentMap(
    collection: DBCollection,
    query: any,
    options: any,
    forceUpdate: boolean,
    startDate: Date,
    endDate: Date
  ): Observable<DBResult[]> {
    let currentMap: string = this.getCurrentMap();

    if (!currentMap) {
      console.error('no map was set to load data for!');
      return of(null);
    }

    if (this.useCaching) {
      let data = null;
      let storageKey: string;
      storageKey = this.getStorageHash([collection.toString(), query, options, startDate, endDate, currentMap]);

      if (!forceUpdate) {
        // get data from cache
        data = this.pendingRequestSubjects[storageKey];
        //data = this.cache[storageKey];
      }

      if (data == null) {
        // no data in cache, load new one
        // but:
        // first check if the same request is already pending
        if (this.pendingRequestSubjects.hasOwnProperty(storageKey)) {
          return (this.pendingRequestSubjects[storageKey] as AsyncSubject<DBResult[]>).asObservable();
        } else {
          // current request is not already pending, so create a new one
          let newSubject: AsyncSubject<DBResult[]> = new AsyncSubject<DBResult[]>();
          this.pendingRequestSubjects[storageKey] = newSubject;

          this.statisticsService
            .findAll(collection, query, options, startDate, endDate)
            .pipe(
              map((logArray) => this.removeUnwantedLogs(logArray)),
              //tap(result => {this.writeToCache(result, storageKey);}),
              takeUntil(componentDestroyed(this))
            )
            .subscribe((results) => {
              newSubject.next(results);
              newSubject.complete();
              delete this.pendingRequestSubjects[storageKey];
            });

          return newSubject.asObservable();
        }
      } else {
        // found data in cache, return it
        return data.asObservable();
      }
    } else {
      return this.statisticsService.findAll(collection, query, options, startDate, endDate).pipe(
        map((logArray) => this.removeUnwantedLogs(logArray)),
        takeUntil(componentDestroyed(this))
      );
    }
  }

  /*private writeToCache(result: DBResult[], storageKey: string) {

    if (this.useCaching && result && result.length) {
      try {
        this.cache[storageKey] = result;
      }
      catch (e) {
        console.warn("can't cache result, probably too big.");
      }
    }

  }*/

  private getStorageHash(vars) {
    let result: string = '';

    vars.forEach((val) => {
      result += JSON.stringify(val);
    });

    result = '_' + btoa(result);
    return result;
  }
}
