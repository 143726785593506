import { Injectable, Injector, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, retry, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as _ from 'lodash';
import { UserAccountService } from '../user-account.service';
import { statisticsServerUrl } from './server-endpoints';

export interface DBResult {
  app: {
    major: number;
    minor: number;
    patch: number;
  };
  data: any;
  timestamp: number;
  dateTimeReadable: string;
  dateTimeReceived: number;
  dateTimeReceivedReadable: string;
  device: {
    manufacturer: string;
    model: string;
    platform: string;
    uuid: string;
  };
  deviceId: string;
  envTag: boolean;
  isTestDevice: boolean;
  isWzTerminal: boolean;
  isDemoInProgress: boolean;
  ipAddress: string;
  map: {
    id: string;
    title: string;
    revision: number;
  };
  size: number;
  uuid: string;
  _id: string;
}

export enum DBCollection {
  addToCart = 'add_to_cart',
  destinationFound = 'destination_found',
  destinationReached = 'destination_reached',
  isLevelOffroute = 'is_level_offroute',
  navStart = 'nav_start',
  positionUpdate = 'position_update',
  searchTerm = 'search_term',
}

export enum Map { // TODO: Maps und Accountberechtigungen auf map ids umsgtellen weil map naes können sich mal ändern durch umbennnenung
  digitalChurch = 'DIGITAL CHURCH',
  edekaHatle = 'EDEKA Center Hatlé',
  eurogressAachen = 'Eurogress Aachen',
  luisenhospitalAachen = 'Luisenhospital Aachen',
  marienhospitalAachen = 'Marienhospital Aachen',
  mobauAachen = 'Mobau Aachen',
  uniklinikAachen = 'Uniklinik RWTH Aachen',
  apagHbf = 'APAG Parkhaus am HBF',
  kreisverwaltungViersen = 'Kreisverwaltung Viersen',
  betlehemStolberg = 'Bethlehem Gesundheitszentrum Stolberg',
  heliosWuppertal = 'Helios Universitätsklinikum Wuppertal',
  rheinKreisNeussStandortGrevenbroich = 'Rhein-Kreis Neuss - Standort Grevenbroich',
  rheinKreisNeussStandortNeuss = 'Rhein-Kreis Neuss - Standort Neuss',
  StFranziskusMuenster = 'St. Franziskus-Hospital Münster',
  alle = 'alle',
}

@Injectable({
  providedIn: 'root',
})
export class StatisticsServerService implements OnDestroy {
  private customerMap: Map;

  // private statisticsServerUrl: string = "http://192.168.178.74:1716/stats";
  // private statisticsServerUrl: string = "http://localhost:1717/stats";
  private serverPw: string = 'BananasInPyjamas';

  constructor(private http: HttpClient, private injector: Injector) {}

  ngOnDestroy() {}

  setCurrentMap(map: Map) {
    this.customerMap = map;
  }

  getCurrentMap(): Map {
    return this.customerMap;
  }

  findOne(
    collectionName: DBCollection,
    query: {},
    options: {},
    startDate: Date,
    endDate: Date
  ): Observable<DBResult[]> {
    return this.find('findOne', collectionName, query, options, startDate, endDate);
  }

  findAll(
    collectionName: DBCollection,
    query: {},
    options: {},
    startDate: Date,
    endDate: Date
  ): Observable<DBResult[]> {
    return this.find('findAll', collectionName, query, options, startDate, endDate);
  }

  private find(
    actionName: string,
    collectionName: DBCollection,
    query: {},
    options: {},
    startDate: Date,
    endDate: Date
  ): Observable<DBResult[]> {
    let postObj = {
      statRequestPw: this.serverPw,
      action: actionName,
      collection: collectionName,
      query: query,
      options: options,
    };

    postObj = this.setDefaultQueryParams(postObj, startDate, endDate);
    postObj = this.setDefaultOptionParams(postObj, collectionName);

    const accountService = this.injector.get(UserAccountService);
    const user = accountService.getCurrentUser();
    const token = accountService.getCurrentToken();
    const headers = new HttpHeaders({
      User: user.username,
      Authorization: token,
    });

    return this.http
      .post<DBResult[]>(statisticsServerUrl, postObj, { headers })
      .pipe(debounceTime(20), retry(2), catchError(this.handleError), takeUntil(componentDestroyed(this)));
  }

  private setDefaultOptionParams(postObj: any, collection: DBCollection): any {
    let returnObj: any = _.cloneDeep(postObj);
    returnObj.options.projection = {};

    let unwantedGeneralProperties: string[] = [
      '_id',
      'uuid',
      'deviceId',
      'envTag',
      'size',
      'ipAddress',
      'dateTimeReadable',
      'dateTimeReceived',
      'dateTimeReceivedReadable',
      'device.available',
      'device.version',
      'device.cordova',
      'device.isVirtual',
      'device.serial',
      'map.revision',
      'app.patch',
    ];

    let unwantedDataProperties: string[] = [];

    switch (collection) {
      case DBCollection.destinationFound:
        unwantedDataProperties = [
          'data.destinationFound.targetDistanceMeterStateless',
          'data.destinationFound.targetDistanceMeterStateBased',
          'data.destinationFound.curPosStateless.snapData',
          'data.destinationFound.curPosStateless.preSnap',
          'data.destinationFound.destination',
        ];
        break;

      case DBCollection.positionUpdate:
        unwantedDataProperties = [
          'data.positionUpdate.curPosStateless.snapData',
          'data.positionUpdate.curPosStateless.preSnap',
        ];
        break;
    }

    let unwantedFinalProperties: string[] = unwantedGeneralProperties.concat(unwantedDataProperties);

    unwantedFinalProperties.forEach((prop) => {
      returnObj.options.projection[prop] = 0;
    });

    return returnObj;
  }

  private setDefaultQueryParams(postObj: any, startDate: Date, endDate: Date): any {
    let returnObj = _.cloneDeep(postObj);

    if (this.customerMap !== Map.alle) {
      returnObj.query['map.title'] = this.customerMap;
    } else {
      console.warn('loading all maps can crash your browser ._.');
    }
    returnObj.query['envTag'] = false;
    returnObj.query['app.major'] = { $gte: 4 };
    returnObj.query['timestamp'] = {
      $gte: this.getStartTimestamp(startDate),
      $lte: this.getEndTimestamp(endDate),
    };

    return returnObj;
  }

  private getStartTimestamp(d: Date): number {
    d.setHours(0, 0, 0, 0);
    return Date.parse(d.toISOString());
  }

  private getEndTimestamp(d: Date): number {
    d.setHours(23, 59, 59, 999);
    return Date.parse(d.toISOString());
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + 'body was: ', error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
