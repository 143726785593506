import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, flatMap, tap } from 'rxjs/operators';
import * as LZString from 'lz-string';

import { StatisticsServerService } from './server-connection/statistics-server.service';

const mapServerOverviewUrl = 'https://maps.wegzwei.com/api/maps';
const mapServerDownloadUrl = 'https://maps.wegzwei.com/api/maps/zip/';

class MapServerOverviewMap {
  title: string;
  _id: string;
}

class MapServerDownloadMap {
  _id: string;
  zip: string;
}

const mapCache = {};

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  constructor(private http: HttpClient, private statServer: StatisticsServerService) {}

  public getCurrentMapModel(): Observable<any> {
    let currentMap: string = this.statServer.getCurrentMap();
    let selectedMapServerMap = null;

    if (currentMap) {
      return this.getListOfServerMaps().pipe(
        flatMap((data: MapServerOverviewMap[]) => {
          selectedMapServerMap = data.find((item) => {
            return item.title == currentMap;
          });

          if (selectedMapServerMap) {
            // if ()

            console.info('getting map: ', currentMap);
            return this.getUncompressedMapObj(selectedMapServerMap);
          } else {
            console.error('currentMap download not available: ', currentMap);
            return of(null);
          }
        })
      );
    } else {
      console.error('currentMap download not available2: ', currentMap);
      return of(null);
    }
  }

  public getListOfServerMaps(): Observable<MapServerOverviewMap[]> {
    return this.http.get<MapServerOverviewMap[]>(mapServerOverviewUrl);
  }

  private getUncompressedMapObj(mapFile: MapServerOverviewMap): Observable<any> {
    const mapId = mapFile._id;
    if (mapCache[mapId]) {
      console.log('map: cache hit');
      return of(mapCache[mapId]);
    } else {
      console.log('map: cache miss');
      return this.downloadMap(mapFile._id).pipe(
        map((download: MapServerDownloadMap) => {
          let zipCompressed = download.zip;
          let zipUncompressed: any = JSON.parse(LZString.decompressFromUTF16(zipCompressed));
          return zipUncompressed;
        }),
        tap((zipUncompressed) => {
          mapCache[mapFile._id] = zipUncompressed;
        })
      );
    }
  }

  private downloadMap(mapId: string): Observable<MapServerDownloadMap> {
    return this.http.get<MapServerDownloadMap>(mapServerDownloadUrl + mapId);
  }
}
