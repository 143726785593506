import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { User, UserAccountService } from '../../user-account.service';
import { DataHubService } from '../../server-connection/data-hub.service';
import { MapsService } from '../../maps.service';
import { Map } from '../../server-connection/statistics-server.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'howtos',
  templateUrl: './howtos.component.html',
  styleUrls: ['./howtos.component.sass', './howtos.component.scss'],
})
export class HowtosComponent implements OnInit {
  urlUsername: string;
  user: any;
  // user: new User();
  failedLoginAttempt: boolean = false;
  startLocation: string;
  destinationLocation: string;
  indoorMapsUrl: string;
  indoorMapsUrlQR: string;
  indoorMapsUrlSafe: SafeResourceUrl;
  refreshIframeVar: boolean;
  iframeWidth: string
  iframeHeight:string;
  iframeHeightRefreshed: string;
  iframeWidthRefreshed: string;


  constructor(
    private route: ActivatedRoute,
    private accountService: UserAccountService,
    private router: Router,
    private dataHubService: DataHubService,
    private mapsService: MapsService,
    public sanitizer: DomSanitizer
  ) {


    this.accountService.currentUser$
      // .pipe(
      //     takeUntil(componentDestroyed(this))
      // )
      .subscribe((usr) => {
        if (!usr) {
          console.log('not logged in! Redirecting..');
          this.router.navigate(['login']);
        } else {
          console.log('logged in as', usr.username);
          console.log('user', usr);
          this.user = usr;


        }
      });
  }

  ngOnInit() {
    const id: string = this.route.snapshot.paramMap.get('id');
    this.generateIndoorMapsUrl();
    this.refreshIframeVar = false;
    this.iframeHeight = "600";
    this.iframeWidth = "900";
    this.refreshIframe();

    if (id !== null) {
      this.urlUsername = id;
      this.user.username = id;
    }
  }

  generateIndoorMapsUrl() {


    const locationIdLocal = this.user.mapsData[0].locationId;

      let indoorMapsQuery = "";

      if (this.startLocation || this.destinationLocation) {
        indoorMapsQuery += "&";
      }
      if (this.destinationLocation) {
        indoorMapsQuery += "q=" + encodeURIComponent(this.destinationLocation);
      }
      if (this.startLocation && this.destinationLocation) {
        indoorMapsQuery += "&";
      }

      if (this.startLocation) {
        indoorMapsQuery += "qs=" + encodeURIComponent(this.startLocation);
      }
      this.indoorMapsUrl = "https://maps.wegzwei.com/web/#/app/b?v_id=" + locationIdLocal + indoorMapsQuery;

      this.indoorMapsUrlQR = "https://api.qrserver.com/v1/create-qr-code/?data=" + encodeURIComponent("https://maps.wegzwei.com/web/#/app/b?v_id=") + locationIdLocal + encodeURIComponent(indoorMapsQuery) + "&amp;size=300x300"
console.log("jou jou: " + this.indoorMapsUrlQR);

      this.indoorMapsUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.indoorMapsUrl);


      this.refreshIframe();

  }

  refreshIframe() {
    console.log("refreshing iframe...");
    this.refreshIframeVar = true;

    setTimeout(() => {
      this.refreshIframeVar = false;
      this.iframeHeightRefreshed = this.iframeHeight;
      this.iframeWidthRefreshed = this.iframeWidth;

    }, 2000);
  }


  getMapTitleForNeonMaps(): any {
    const activeMapTitle = this.dataHubService.getCurrentMap();
    if (activeMapTitle) {
      return activeMapTitle;
    }

    const mapTitles = this.accountService.getCurrentMapTitles();
    if (mapTitles && mapTitles.length === 1) {
      return mapTitles[0];
    }

    const hasAccessToUka = mapTitles && mapTitles.includes(Map.uniklinikAachen);
    if (hasAccessToUka) {
      return Map.uniklinikAachen;
    }

    return _.sample(mapTitles);
  }
}
