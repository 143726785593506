import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import * as THREE from 'three-full';
import { IMyDateRange, IMyDateRangeModel, IMyDrpOptions } from 'mydaterangepicker';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as _ from 'lodash';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subject, Subscription, of, from } from 'rxjs';
import { takeUntil, tap, concatMap, mergeMap, map } from 'rxjs/operators';

import { MapsService } from '../../maps.service';
import { DataHubService, LogDataTypes } from '../../server-connection/data-hub.service';
import { MessageService } from '../../message.service';
import { DBCollection, DBResult } from '../../server-connection/statistics-server.service';
import { floorPlanTextureService as FloorPlanTextureService } from './floor-plan-texture.service.js';

@Component({
  selector: 'app-movement-statistics',
  templateUrl: './movement-statistics.component.html',
  styleUrls: ['./movement-statistics.component.scss'],
})
export class MovementStatisticsComponent implements OnInit, OnDestroy {
  @ViewChild('header') MyProp: ElementRef;
  @ViewChild('scrollableDiv') ScrollableDiv: ElementRef;
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.onThreeCanvasResize(event);
  }

  rangePickerOptions: IMyDrpOptions = {
    dateFormat: 'dd.mm.yyyy',
    editableDateRangeField: false,
    openSelectorOnInputClick: true,
  };
  dateRangeModel: IMyDateRange;

  mapIsLoading: boolean = false;

  private scene: THREE.Scene;
  private camera: THREE.Camera;
  private renderer: THREE.Renderer;
  private camControls: any;
  private raycaster: THREE.Raycaster;

  floorPlanTexturTiles = [];

  mouseCoords: THREE.Vector2;
  selectedHeatmapTile: any;
  filteredUserId: string;

  threeContainer: any;
  threeWidth: number;
  threeHeight: number;

  currentLevelName: string;
  currentMapModel: any;
  mapLevelTranslation: any;
  allLevelTranslations: any[] = [];
  levelNames: any[] = [];
  selectedLevelObj: any;
  mapLevelBoundingBox: any;

  private heatmap2DPlanes = [];
  private laufwegeEdges = [];
  private laufwegePlane: any = null;

  private userHeatmap2DPlanes = [];

  heatmapVisible: boolean = true;
  laufwegeVisible: boolean = false;

  selectedHeatmapMode: number = 0;

  private msgSubscription: Subscription;

  private combinedDataArrray: DBResult[] = [];
  private selectedUserTimelineEvents: DBResult[] = [];
  private positionMarker: THREE.Mesh = null;

  private isCamPosLerpActive: boolean = false;
  private camPosLerpStartVec: THREE.Vector2 = null;
  private camPosLerpEndVec: THREE.Vector2 = null;
  private camPosLerpPercentage: number = 0;

  selectedEvent: any = null;
  eventsCanGoBack: boolean = false;
  eventsCanGoForward: boolean = false;
  selectedEventIndex: number = -1;
  isEventStreamPlaying: boolean = false;
  private activeStreamPlayTimer: any = null;

  private startDate: Date;
  private endDate: Date;
  private subscriptions = [];

  constructor(private mapServer: MapsService, private dataService: DataHubService, private msgService: MessageService) {
    this.mouseCoords = new THREE.Vector2();

    let today: Date = new Date();
    let weekAgo: Date = new Date(today);
    weekAgo.setDate(today.getDate() - 7);

    this.startDate = weekAgo;
    this.endDate = today;

    this.updateDateRangeModel();
  }

  ngOnInit() {
    this.setupTHREE();

    this.msgSubscription = this.msgService.missionAnnounced$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((mission) => {
        if (mission != '') {
          let msgObj = JSON.parse(mission);

          if (msgObj.type == 'map') {
            this.resetMapFilter();
            this.updateMapLevel('');
            this.resetCameraView();
            this.reloadData();
          } else if (msgObj.type == 'selectUserId') {
            this.filterUserOnClick(msgObj.data);
            this.MyProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else if (msgObj.type == 'dateRangeChange') {
            this.startDate = new Date(Date.parse(msgObj.data.start));
            this.endDate = new Date(Date.parse(msgObj.data.end));
            this.updateDateRangeModel();
            this.reloadData();
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  updateDateRangeModel() {
    this.dateRangeModel = {
      beginDate: {
        year: this.startDate.getFullYear(),
        month: this.startDate.getMonth() + 1,
        day: this.startDate.getDate(),
      },
      endDate: {
        year: this.endDate.getFullYear(),
        month: this.endDate.getMonth() + 1,
        day: this.endDate.getDate(),
      },
    };
  }

  private unsubscribe() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setupTHREE() {
    this.scene = new THREE.Scene();
    this.threeContainer = document.getElementById('threejsContainer');

    this.renderer = new THREE.WebGLRenderer({ antialias: false });

    this.raycaster = new THREE.Raycaster();

    this.renderer.setClearColor(0xeeeeee, 1);

    this.updateThreeContainerBounds();

    this.threeContainer.appendChild(this.renderer.domElement);

    this.setupCamera();
    this.setupLights();
    this.setupControls(this.threeContainer);
    this.updateMapLevel('');
    this.resetCameraView();

    this.animate();
  }

  createPositionMarker() {
    let geometry = new THREE.CylinderBufferGeometry(0.75, 0.25, 11, 32);
    let material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    let cylinder = new THREE.Mesh(geometry, material);
    this.positionMarker = cylinder;
  }

  movePositionMarker(x: number, y: number) {
    if (x != null && y != null) {
      if (this.positionMarker == null) {
        this.createPositionMarker();
        this.scene.add(this.positionMarker);
      }
      //this.positionMarker.position.set(x, 5, y);
    }
  }

  hidePositionMarker() {
    if (this.positionMarker) {
      this.scene.remove(this.positionMarker);
      this.positionMarker = null;
    }
  }

  onThreeCanvasResize(event) {
    //console.warn("eeeeek");
    this.updateThreeContainerBounds();
    this.updateCameraAspect();
  }

  updateCameraAspect() {
    let aspect: number = this.threeWidth / this.threeHeight;
    let d: number = 20;

    this.camera.left = -d * aspect;
    this.camera.right = d * aspect;
    this.camera.top = d;
    this.camera.bottom = -d;

    this.camera.updateProjectionMatrix();
  }

  resetCameraZoomAndPosition(beaconMap, dimensionsLevel) {
    var bounds = determineFloorPlanTextureBounds(beaconMap, dimensionsLevel);

    var x = (bounds.xMin + bounds.xMax) / 2;
    var z = -(bounds.yMin + bounds.yMax) / 2;
    const cameraHeight = 200;

    // Set camera
    this.camera.position.set(x, cameraHeight, z);
    this.camera.zoom = 0.15;
    this.camera.updateProjectionMatrix();

    // Set camera-controls delegate
    let pointOnPlane = new THREE.Vector3(x, 0, z);
    this.camControls.target.set(pointOnPlane.x, pointOnPlane.y, pointOnPlane.z);
    this.camControls.update();
  }

  resetCameraView() {
    if (this.camera && this.camControls) {
      this.camera.position.set(50, 200, -50);
      let down = new THREE.Vector3(50, 0, -50);
      this.camera.lookAt(down);
      this.camControls.target.set(down.x, down.y, down.z);
      this.camControls.update();
    }
  }

  centerCameraOnModel(model) {
    let box = new THREE.Box3().setFromObject(model);

    let modelSize = new THREE.Vector3();
    modelSize = box.getSize(modelSize);

    //console.warn(box.min, box.max, modelSize);

    let centerX = box.min.x + modelSize.x / 2;
    let centerZ = box.min.z + modelSize.z / 2;

    this.camera.position.set(centerX, 200, centerZ);
    this.camControls.target.set(centerX, 0, centerZ);
    this.camControls.update();
  }

  flyCameraToPos(x: number, y: number) {
    if (x != null && y != null) {
      this.camPosLerpStartVec = new THREE.Vector2(this.camControls.target.x, this.camControls.target.z);
      this.camPosLerpEndVec = new THREE.Vector2(x, y);
      this.camPosLerpPercentage = 0;
      this.isCamPosLerpActive = true;
    }
  }

  setupCamera() {
    let aspect: number = this.threeWidth / this.threeHeight;
    let d: number = 20;

    this.camera = new THREE.OrthographicCamera(-d * aspect, d * aspect, d, -d, 0.1, 10000);
  }

  setupControls(domContainer) {
    this.camControls = new THREE.MapControls(this.camera, domContainer);

    this.camControls.maxPolarAngle = Math.PI / 2.1; // damit Kamera nicht unter die Karte rotieren kann
    this.camControls.minZoom = 0.1;
    this.camControls.maxZoom = 10;

    this.camControls.update();
  }

  updateThreeContainerBounds() {
    this.threeWidth = this.threeContainer.offsetWidth;
    this.threeHeight = this.threeContainer.offsetHeight;
    this.renderer.setSize(this.threeWidth, this.threeHeight);
  }

  setupLights() {
    let light = new THREE.DirectionalLight(0xaaaaaa);
    light.position.set(0, 50, 0);
    this.scene.add(light);

    let light3 = new THREE.AmbientLight(0xaaaaaa);
    this.scene.add(light3);

    this.scene.add(new THREE.AmbientLight(0xcccccc, 0.1));
  }

  setShadows(obj) {
    obj.castShadow = true;
    obj.receiveShadow = true;

    if (obj.children && obj.children.length && obj.children.length > 0) {
      obj.children.forEach((item) => {
        this.setShadows(item);
      });
    }
  }

  /**
   * debounce: ugly hack to avoid multiple concurrent calls to updateMapLevel
   * use 'switchMap' for a clean solution. switchMap will cancel any previous running call on emit
   */
  updateMapLevel = _.debounce((mapLevelName) => {
    console.log('[ UpdateMapLevel ] Request dimensions ...');
    if (this.currentLevelName === mapLevelName && mapLevelName != null && mapLevelName != '') {
      return;
    }

    this.mapServer.getCurrentMapModel().subscribe((item) => {
      console.log('[ UpdateMapLevel ] Received dimensions ');
      const beaconMap = item;
      if (!item || !item.levelData) {
        return;
      }

      let levelData = item.levelData;
      let level;
      this.allLevelTranslations = [];
      let levelNamesArray = [];

      levelData.forEach((lvl) => {
        let obj = {
          levelName: lvl.levelName,
          levelSort: lvl.levelSort,
        };
        levelNamesArray.push(obj);

        if (lvl.mapModelTranslation) {
          this.allLevelTranslations.push({
            levelName: lvl.levelName,
            x: lvl.mapModelTranslation.x,
            y: lvl.mapModelTranslation.y,
          });
        }
      });
      levelNamesArray.sort((a, b) => {
        return a.levelSort - b.levelSort;
      });
      this.levelNames = levelNamesArray;
      if (mapLevelName && mapLevelName != '') {
        level = levelData.find((lvl) => {
          return lvl.levelName == mapLevelName;
        });
      }
      if (!level) {
        level = levelData.find((lvl) => {
          return lvl.levelSort == 0;
        });
      }
      if (!level) {
        level = levelData[0]; // fallback
        console.info('had to use fallback level selection, because no level with specified name or levelSort 0');
      }
      this.selectedLevelObj = this.levelNames.find((lvl) => {
        return lvl.levelName == level.levelName;
      });

      // 3d collada map model
      // if (this.currentMapModel) {
      //   this.scene.remove(this.currentMapModel);
      // }
      // let levelMapModel;
      // if (level && level.textureBase64) {
      //   levelMapModel = level.textureBase64;
      //   this.currentLevelName = level.levelName;
      //   this.mapLevelTranslation = this.getModelTranslation(level);
      //   this.loadLevel(levelMapModel, this.mapLevelTranslation);
      // }

      // floor plan
      this.removeFloorPlan(this.scene, this.floorPlanTexturTiles);
      if (level && level.levelName !== '') {
        // this.resetCameraZoomAndPosition(beacon, dime);

        this.currentLevelName = level.levelName;
        this.mapLevelTranslation = this.getModelTranslation(level);
        this.addFloorPlans({ beaconMap, levelRecord: level });
      }
    });
  }, 100);

  getModelTranslation(level) {
    let translation = {
      x: 0,
      y: 0,
    };

    if (level.mapModelTranslation) {
      translation = level.mapModelTranslation;
    }

    return translation;
  }

  loadLevel(levelMapModel, mapLevelTranslation) {
    this.mapIsLoading = true;

    let loader = new THREE.ColladaLoader();
    let colladaModel = loader.parse(levelMapModel);

    //setup scene
    let mapLevelScene = colladaModel.scene;
    mapLevelScene.position.x = mapLevelTranslation.x;
    mapLevelScene.position.y = 0;
    mapLevelScene.position.z = -mapLevelTranslation.y;

    // Loop through scene obj, set material sides to front side
    var setSingleSided = function (obj) {
      if (obj.material && obj.material.materials) {
        // Set all materials to single sided
        obj.material.materials.forEach((threeMaterial) => {
          threeMaterial.side = THREE.FrontSide;
        });
      } else if (obj.children && obj.children.length) {
        // Recurse
        obj.children.forEach((child) => {
          setSingleSided(child);
        });
      }
    };
    setSingleSided(mapLevelScene);
    //setLevelMapModelOpacity(mapLevelScene, 0.5);
    //this.setBoundingBox(mapLevelScene);

    if (this.currentMapModel) {
      this.scene.remove(this.currentMapModel);
    }

    this.scene.add(mapLevelScene);
    this.currentMapModel = mapLevelScene;

    if (this.heatmapVisible) {
      this.updateHeatmapPlanes();
    } else {
      this.removeAllHeatmapPlanes();
      this.removeAllUserHeatmapPlanes();
    }

    if (this.laufwegeVisible) {
      this.updateLaufwegeEdges();
    } else {
      this.removeAllLaufwegeEdges();
    }

    this.mapIsLoading = false;

    //let axesHelper = new THREE.AxesHelper( 5 );
    //this.scene.add( axesHelper );

    //this.centerCameraOnModel(mapLevelScene);
  }

  /*setBoundingBox(mapLevelScene) {
    console.warn("setting box: ", mapLevelScene);
    if (mapLevelScene) {
      let box = new THREE.Box3().setFromObject(mapLevelScene);
      this.mapLevelBoundingBox = box;
    }
  }*/

  animate() {
    requestAnimationFrame(this.animate.bind(this));

    if (this.isCamPosLerpActive) {
      let newPercentage: number = Math.min(this.camPosLerpPercentage + 0.1, 1);
      let currentPos: THREE.Vector2 = _.cloneDeep(this.camPosLerpStartVec).lerp(this.camPosLerpEndVec, newPercentage);

      //this.camControls.target.set(currentPos.x, 0, currentPos.y);

      this.camera.position.set(currentPos.x, 200, currentPos.y);
      let down = new THREE.Vector3(currentPos.x, 0, currentPos.y);
      this.camera.lookAt(down);
      this.camControls.target.set(down.x, down.y, down.z);

      this.positionMarker.position.set(currentPos.x, 5, currentPos.y);

      if (newPercentage < 1) {
        this.camPosLerpPercentage = newPercentage;
      } else {
        this.isCamPosLerpActive = false;
      }
    }

    this.camControls.update();

    this.renderer.render(this.scene, this.camera);
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if (event.beginJsDate && event.endJsDate) {
      this.startDate = event.beginJsDate;
      this.endDate = event.endJsDate;
      this.reloadData();

      let msgObj = {
        type: 'dateRangeChange',
        data: {
          start: event.beginJsDate,
          end: event.endJsDate,
        },
      };

      this.msgService.announceMission(JSON.stringify(msgObj));
    }
  }

  onSelectedLevelChanged(newLevelObj: any) {
    this.selectedLevelObj = newLevelObj;
    this.updateMapLevel(newLevelObj.levelName);
    this.updateHeatmapPlanes();
    this.updateLaufwegeEdges();
  }

  reloadData() {
    this.mapIsLoading = true;
    this.removeAllHeatmapPlanes();
    this.removeAllUserHeatmapPlanes();
    this.removeAllLaufwegeEdges();

    console.info('reloading heatmap data');

    this.unsubscribe();
    this.startDataSubscriptions();
  }

  startDataSubscriptions() {
    let obs1$ = this.dataService.fetchDataForCurrentMap(
      DBCollection.navStart,
      {},
      {},
      false,
      this.startDate,
      this.endDate
    );
    let obs2$ = this.dataService.fetchDataForCurrentMap(
      DBCollection.destinationFound,
      {},
      {},
      false,
      this.startDate,
      this.endDate
    );
    let obs3$ = this.dataService.fetchDataForCurrentMap(
      DBCollection.addToCart,
      {},
      {},
      false,
      this.startDate,
      this.endDate
    );
    let obs4$ = this.dataService
      .fetchDataForCurrentMap(DBCollection.positionUpdate, {}, {}, false, this.startDate, this.endDate)
      .pipe(
        tap((results) => {
          console.info('finished reloading heatmap data');
          this.parsePositionUpdates(results as DBResult[]);
          this.mapIsLoading = false;
        })
      );
    let obs5$ = this.dataService.fetchDataForCurrentMap(
      DBCollection.searchTerm,
      {},
      {},
      false,
      this.startDate,
      this.endDate
    );

    let allSubscriptions = forkJoin(obs1$, obs2$, obs3$, obs4$, obs5$).subscribe(
      ([navStartResults, destFoundResults, addCartResults, posUpdResults, searchTResults]) => {
        let allResultsCombined: DBResult[] = [
          ...navStartResults,
          ...destFoundResults,
          ...addCartResults,
          ...posUpdResults,
          ...searchTResults,
        ];
        this.handleCombinedData(allResultsCombined);
      }
    );

    this.subscriptions.push(allSubscriptions);
  }

  handleCombinedData(results: DBResult[]) {
    this.combinedDataArrray = results;
  }

  createUserTimeline() {
    if (this.combinedDataArrray && this.combinedDataArrray.length && this.filteredUserId != null) {
      let eventsForSelectedUser: DBResult[] = this.combinedDataArrray.filter((item) => {
        if (item.device && item.device.uuid) {
          return this.filteredUserId == item.device.uuid;
        } else {
          return false;
        }
      });

      let sortedEvents: DBResult[] = _.sortBy(eventsForSelectedUser, 'timestamp');
      let allEventsWithPositionsAssigned: any[] = this.addPositionsToAllEvents(sortedEvents);
      let posChangesInsteadOfPosUpdates: any[] = this.combinePosUpdatesToPosChanges(allEventsWithPositionsAssigned);
      let enrichedEvents: any[] = posChangesInsteadOfPosUpdates.map((item) => {
        return this.enrichEventData(item);
      });

      this.selectedUserTimelineEvents = enrichedEvents;
    } else {
      this.selectedUserTimelineEvents = [];
    }
  }

  addPositionsToAllEvents(events: DBResult[]): any[] {
    let results: any[] = _.cloneDeep(events);

    results.forEach((item, i) => {
      let log: DBResult = item as DBResult;

      if (log.data.positionUpdate) {
        // copy coordinates & level to top level for easier access later
        this.addXYLevelToEvent(item, item);
      } else {
        // not a pos update, so we need to add x,y to it
        let targetTimestamp: number = log.timestamp;
        let prevPosUpdate: any = this.findPrevPosUpdate(events, i);
        let nextPosUpdate: any = this.findNextPosUpdate(events, i);

        if (prevPosUpdate == null) {
          prevPosUpdate = {
            timestamp: Number.MAX_SAFE_INTEGER,
          };
        }

        if (nextPosUpdate == null) {
          nextPosUpdate = {
            timestamp: Number.MAX_SAFE_INTEGER,
          };
        }

        let prevTimeDiff: number = Math.abs(targetTimestamp - prevPosUpdate.timestamp);
        let nextTimeDiff: number = Math.abs(targetTimestamp - nextPosUpdate.timestamp);

        if (prevTimeDiff < nextTimeDiff && prevPosUpdate.data != null) {
          // previous posUpdate is closer to current event, so use its coordinates
          this.addXYLevelToEvent(item, prevPosUpdate);
        } else if (nextPosUpdate.data != null) {
          // use next posUpdate
          this.addXYLevelToEvent(item, nextPosUpdate);
        } else {
          console.error('no pos update found for this event!');
          item.x = null;
          item.y = null;
          item.level = null;
        }
      }
    });

    return results;
  }

  addXYLevelToEvent(targetEvent: any, sourcePosUpdateEvent: DBResult) {
    let posUpdate: LogDataTypes.positionUpdate = sourcePosUpdateEvent.data
      .positionUpdate as LogDataTypes.positionUpdate;
    let coords: any = this.getCoordinatesFromPosUpdate(posUpdate);
    let level: string = posUpdate.curPosStateless.level;
    targetEvent.x = coords.x;
    targetEvent.y = coords.y;
    targetEvent.level = level;
  }

  findPrevPosUpdate(events: DBResult[], startIndex: number): DBResult {
    for (let i = startIndex; i >= 0; i--) {
      if (events[i].data.positionUpdate) {
        return events[i];
      }
    }
  }

  findNextPosUpdate(events: DBResult[], startIndex: number): DBResult {
    for (let i = startIndex; i < events.length; i++) {
      if (events[i].data.positionUpdate) {
        return events[i];
      }
    }
  }

  combinePosUpdatesToPosChanges(events: any[]): any[] {
    let results: any[] = [];

    let lastAddedCoords: any = null;

    events.forEach((item, i) => {
      if (item.data.positionUpdate) {
        let currentCoords: any = this.getCoordinatesFromPosUpdate(item.data.positionUpdate);
        currentCoords.x = Math.round(currentCoords.x);
        currentCoords.y = Math.round(currentCoords.y);

        if (lastAddedCoords == null || currentCoords.x !== lastAddedCoords.x || currentCoords.y !== lastAddedCoords.y) {
          // we haven't added any coordinates yet or the current ones are different from the last added ones
          results.push(item);
          lastAddedCoords = currentCoords;
        }
      } else {
        results.push(item);
      }
    });

    return results;
  }

  enrichEventData(event: any): any {
    let result: any = _.cloneDeep(event);

    result.dateTimeReadable = new Date(result.timestamp).toLocaleString('de-DE');
    result.eventType = '';
    result.eventContents = [];

    if (result.data.add2cart) {
      result.eventType = 'Ziel hinzugefügt';
      result.eventContents.push('Suchanfrage: ' + result.data.add2cart.query);
      result.eventContents.push('Ziel hinzugefügt: ' + result.data.add2cart.item);
    } else if (result.data.destinationFound && result.data.destinationFound.found === false) {
      result.eventType = 'Navigationsziel nicht erreicht';
      result.eventContents.push('Ziel war: ' + result.data.destinationFound.destinationTitle);
    } else if (result.data.destinationFound && result.data.destinationFound.found === true) {
      result.eventType = 'Navigationsziel erreicht';
      result.eventContents.push('Ziel war: ' + result.data.destinationFound.destinationTitle);
    } else if (result.data.startNavigation) {
      result.eventType = 'Navigation gestartet';
      result.eventContents.push('Ziele:');
      result.data.startNavigation.chain.forEach((item) => {
        result.eventContents.push(item);
      });
    } else if (result.data.positionUpdate) {
      result.eventType = 'Positionsänderung';
      result.eventContents.push('Koordinaten: ' + Math.round(result.x) + '; ' + Math.round(result.y));
      result.eventContents.push('Etage: ' + result.level);
    } else if (result.data.searchQueryEntered) {
      result.eventType = 'Suchbegriff eingegeben';
      result.eventContents.push('Suchbegriff: ' + result.data.searchQueryEntered.query);
      result.eventContents.push('Suchergebnisse:');
      result.data.searchQueryEntered.results.forEach((item) => {
        result.eventContents.push(item.title);
      });
    } else {
      result.eventType = 'unbekannt';
      console.error('unbekannter log type:', result);
    }

    return result;
  }

  parsePositionUpdates(results: DBResult[]) {
    let levelsDict = [];

    results.forEach((res) => {
      let posUpdate: LogDataTypes.positionUpdate = res.data.positionUpdate as LogDataTypes.positionUpdate;
      let level: string = posUpdate.curPosStateless.level;

      let currentLevelPositions = levelsDict.find((x) => x.level == level);

      let truePos = this.getCoordinatesFromPosUpdate(posUpdate);

      let roundedPos = {
        x: Math.round(truePos.x),
        y: Math.round(truePos.y),
        userId: res.device.uuid,
      };

      let accuratePos = {
        x: _.round(truePos.x, 2),
        y: _.round(truePos.y, 2),
        userId: res.device.uuid,
        timestamp: res.timestamp,
      };

      if (!currentLevelPositions) {
        levelsDict.push({
          level: level,
          positions: [roundedPos],
          accuratePositions: [accuratePos],
        });
      } else {
        currentLevelPositions.positions.push(roundedPos);
        currentLevelPositions.accuratePositions.push(accuratePos);
      }
    });

    this.countPositionOccurances(levelsDict);
    this.createPositionRunsByUsers(levelsDict);
  }

  getCoordinatesFromPosUpdate(posUpdate: LogDataTypes.positionUpdate): any {
    let result: any = {
      x: 0,
      y: 0,
    };

    if (posUpdate.curPosStateBased && posUpdate.curPosStateBased.x && posUpdate.curPosStateBased.y) {
      result.x = posUpdate.curPosStateBased.x;
      result.y = posUpdate.curPosStateBased.y;
    } else {
      result.x = posUpdate.curPosStateless.x;
      result.y = posUpdate.curPosStateless.y;
    }

    return result;
  }

  createPositionRunsByUsers(levelsPosDict: any[]) {
    this.removeAllLaufwegeEdges();
    this.laufwegeEdges = [];

    levelsPosDict.forEach((level) => {
      let levelPosArrByUsers: any[] = this.getPosByUsers(level.accuratePositions);

      if (levelPosArrByUsers && levelPosArrByUsers.length) {
        this.createWalkPathsForLevel(levelPosArrByUsers, level.level);
      }
    });

    this.updateLaufwegeEdges();
  }

  createWalkPathsForLevel(levelPosArrByUsers, level) {
    levelPosArrByUsers.forEach((user) => {
      let positions: any[] = user.logs.map((x) => {
        x.longStamp = x.timestamp;
        x.timestamp = Math.round(x.timestamp / 1000);
        return x;
      });

      let positionsOrdered: any[] = _.orderBy(positions, ['timestamp'], ['asc']);
      this.buildPath(positionsOrdered, level);
    });
  }

  buildPath(positionsOrdered: any[], level: string) {
    let lastPos = null;
    let currentLevelLaufwegeObj = this.laufwegeEdges.find((x) => x.level == level);
    let isNewLevelObj = false;

    if (!currentLevelLaufwegeObj) {
      currentLevelLaufwegeObj = {
        level: level,
        edges: [],
      };
      isNewLevelObj = true;
    }

    let pathColor = this.getRandomBrightColor();

    positionsOrdered.forEach((pos) => {
      if (lastPos) {
        //continue edge

        let timeSpanMs: number = pos.longStamp - lastPos.longStamp;
        let distance: number = this.getDistance(lastPos, pos);
        let speed: number = 0;
        if (timeSpanMs > 0) {
          speed = distance / timeSpanMs / 1000; // Meter pro Sekunde
        }

        if (speed < 5 && distance < 15 && timeSpanMs / 1000 < 300) {
          //keiner läuft schneller als 5 m/s und mehr als 30 Meter Abstand braucht keine Verbindung mehr

          pos.x = pos.x + Math.random() / 2 - 0.25;
          pos.y = pos.y + Math.random() / 2 - 0.25;

          /*if (timeSpanMs / 1000 > 300) {
            // mehr als 5 Minuten Abstand gilt als neuer Durchlauf
              pathColor = this.getRandomBrightColor();
          }*/

          let line = this.createThickLineMesh(lastPos.x, lastPos.y, pos.x, pos.y, 10, 0.1, pathColor);
          line.userData = { userId: pos.userId };
          currentLevelLaufwegeObj.edges.push(line);

          // TODO: use LineSegment for performance
        } else {
          //console.log("speed: ", speed, lastPos, pos);
          pathColor = this.getRandomBrightColor();
        }
      }
      lastPos = pos;
    });

    if (isNewLevelObj) {
      this.laufwegeEdges.push(currentLevelLaufwegeObj);
    }
  }

  getRandomBrightColor() {
    return new THREE.Color(0xffffff).setHex((Math.random() / 2 + 0.4) * 0xffffff);
  }

  getDistance(pos1, pos2): number {
    return Math.sqrt(Math.pow(Math.abs(pos1.x - pos2.x), 2) + Math.pow(Math.abs(pos1.y - pos2.y), 2));
  }

  buildSingleEdge(color, startPos, endPos) {
    let material = new THREE.LineBasicMaterial({ color: color });
    let geometry = new THREE.Geometry();
    geometry.vertices.push(new THREE.Vector3(startPos.x, 10, -startPos.y));
    geometry.vertices.push(new THREE.Vector3(endPos.x, 10, -endPos.y));
    let line = new THREE.Line(geometry, material);

    return line;
  }

  createThickLineMesh(x1, y1, x2, y2, zHeight, thickness, color, opacity?: number) {
    var lineVector = this.get2DVectorFromLine(x1, y1, x2, y2);
    var orthogonalVector = this.getOrthogonal2DVector(lineVector.x, lineVector.y);
    var orthogonalVectorNormalized = new THREE.Vector2(orthogonalVector.x, orthogonalVector.y).normalize();

    var thicknessScalar = thickness / 2;
    orthogonalVectorNormalized.multiplyScalar(thicknessScalar);

    var lineShape = new THREE.Shape();
    // start of line
    lineShape.moveTo(x1 - orthogonalVectorNormalized.x, y1 - orthogonalVectorNormalized.y);
    lineShape.lineTo(x1 + orthogonalVectorNormalized.x, y1 + orthogonalVectorNormalized.y);
    // end of line
    lineShape.lineTo(x2 + orthogonalVectorNormalized.x, y2 + orthogonalVectorNormalized.y);
    lineShape.lineTo(x2 - orthogonalVectorNormalized.x, y2 - orthogonalVectorNormalized.y);

    var geometry = new THREE.ShapeBufferGeometry(lineShape);
    var material = this.getMeshBasicMaterialForColor(color);

    if (opacity) {
      material.opacity = opacity;
      material.transparent = true;
    }

    var lineMesh = new THREE.Mesh(geometry, material);
    lineMesh.position.set(0, zHeight, 0);
    lineMesh.rotateX(-Math.PI / 2);

    return lineMesh;
  }

  get2DVectorFromLine(x1, y1, x2, y2) {
    var xDiff = x2 - x1;
    var yDiff = y2 - y1;
    return { x: xDiff, y: yDiff };
  }

  getOrthogonal2DVector(x, y) {
    return { x: y, y: -x };
  }

  getMeshBasicMaterialForColor(color) {
    var mat = new THREE.MeshBasicMaterial({ color: color });
    return mat;
  }

  getPosByUsers(accuratePositions: any[]): any[] {
    let uniqArr: any = {};

    accuratePositions.forEach((val) => {
      let id: string = val.userId;
      if (uniqArr[id]) {
        uniqArr[id].counter += 1;
        uniqArr[id].logs.push(val);
      } else {
        uniqArr[id] = {};
        uniqArr[id].logs = [];
        uniqArr[id].logs.push(val);
        uniqArr[id].counter = 1;
      }
    });

    let returnArr: any[] = [];
    _.forOwn(uniqArr, (item) => returnArr.push(item));
    returnArr = _.orderBy(returnArr, ['counter'], ['desc']);

    return returnArr;
  }

  countPositionOccurances(levelsDict: any[]) {
    levelsDict.forEach((level) => {
      let positionsCounted = [];

      level.positions.forEach((pos) => {
        let foundCountedPos = positionsCounted.find((item) => item.x == pos.x && item.y == pos.y);

        if (foundCountedPos) {
          foundCountedPos.userIds.push(pos.userId);
          foundCountedPos.userIds = _.uniq(foundCountedPos.userIds);
          foundCountedPos.count += 1;
        } else {
          positionsCounted.push({
            x: pos.x,
            y: pos.y,
            userIds: [pos.userId],
            count: 1,
          });
        }
      });

      level.positionsCounted = positionsCounted;
    });

    this.calcPosOccurancePercentages(levelsDict);
    this.calcUsersPerPosOccurancePercentages(levelsDict);
    //console.warn("lvls:", levelsDict);
  }

  calcUsersPerPosOccurancePercentages(levelsDict: any[]) {
    levelsDict.forEach((level) => {
      let maxCount = 0;

      level.positionsCounted.forEach((pos) => {
        if (pos.userIds.length > maxCount) {
          maxCount = pos.userIds.length;
        }
      });

      level.positionsCounted.forEach((pos) => {
        pos.relativeOccurance = pos.userIds.length / maxCount;
      });
    });

    this.createUsersHeatmap2DPlanes(levelsDict);
  }

  calcPosOccurancePercentages(levelsDict: any[]) {
    levelsDict.forEach((level) => {
      let maxCount = 0;

      level.positionsCounted.forEach((pos) => {
        if (pos.count > maxCount) {
          maxCount = pos.count;
        }
      });

      //console.warn("max is: ", maxCount);

      level.positionsCounted.forEach((pos) => {
        pos.relativeOccurance = pos.count / maxCount;
      });
    });

    //console.warn("lvls:", levelsDict);
    this.createHeatmap2DPlanes(levelsDict);
  }

  createUsersHeatmap2DPlanes(levelsDict: any[]) {
    this.removeAllUserHeatmapPlanes();
    this.userHeatmap2DPlanes = [];

    levelsDict.forEach((level) => {
      let levelName: string = level.level;
      let planes = [];

      level.positionsCounted.forEach((pos) => {
        let height: number = 5 + Number(pos.relativeOccurance) * 4;

        let planeGeo = new THREE.BoxBufferGeometry(1, height, 1); //new THREE.PlaneGeometry(1, 1);
        let planeMat = new THREE.MeshBasicMaterial({
          color: this.getHeatmapPlaneColor(pos.relativeOccurance),
          side: THREE.DoubleSide,
        });
        let plane = new THREE.Mesh(planeGeo, planeMat);

        //planeMat.transparent = true;
        //planeMat.opacity = 0.9;

        plane.position.x = pos.x - 0.5;
        plane.position.y = height / 2;
        plane.position.z = -pos.y + 0.5;

        planeGeo.computeFaceNormals();
        planeGeo.computeBoundingSphere();

        plane.userData = { userIds: pos.userIds };

        planes.push(plane);
      });

      this.userHeatmap2DPlanes.push({
        level: levelName,
        planes: planes,
      });
    });

    if (this.selectedHeatmapMode == 1) {
      this.updateHeatmapPlanes();
    }
  }

  createHeatmap2DPlanes(levelsDict: any[]) {
    this.removeAllHeatmapPlanes();
    this.heatmap2DPlanes = [];

    levelsDict.forEach((level) => {
      let levelName: string = level.level;
      let planes = [];

      level.positionsCounted.forEach((pos) => {
        let height: number = 5 + Number(pos.relativeOccurance) * 4;

        let planeGeo = new THREE.BoxBufferGeometry(1, height, 1); //new THREE.PlaneGeometry(1, 1);
        let planeMat = new THREE.MeshBasicMaterial({
          color: this.getHeatmapPlaneColor(pos.relativeOccurance),
          side: THREE.DoubleSide,
        });
        let plane = new THREE.Mesh(planeGeo, planeMat);

        //planeMat.transparent = true;
        //planeMat.opacity = 0.9;

        plane.position.x = pos.x - 0.5;
        plane.position.y = height / 2;
        plane.position.z = -pos.y + 0.5;

        planeGeo.computeFaceNormals();
        planeGeo.computeBoundingSphere();

        plane.userData = { userIds: pos.userIds };

        planes.push(plane);
      });

      this.heatmap2DPlanes.push({
        level: levelName,
        planes: planes,
      });
    });

    if (this.selectedHeatmapMode == 0) {
      this.updateHeatmapPlanes();
    }
  }

  getHeatmapPlaneColor(percentage: number) {
    let scaledPerc: number = this.getScaledPercentage(percentage);

    //console.warn("percentage logE:": logScaledPerc);

    let green = new THREE.Color('green');
    let yellow = new THREE.Color('yellow');
    let red = new THREE.Color('red');

    if (scaledPerc < 0.25) {
      return green.lerpHSL(yellow, scaledPerc / 0.25);
    } else {
      return yellow.lerpHSL(red, (scaledPerc - 0.25) / 0.75);
    }
  }

  getScaledPercentage(perc: number): number {
    //return Math.log(perc + 1) / Math.log(2);
    return Math.sqrt(perc);
  }

  removeAllHeatmapPlanes() {
    this.heatmap2DPlanes.forEach((item) => {
      item.planes.forEach((planeObj) => {
        this.scene.remove(planeObj);
      });
    });
  }

  removeAllUserHeatmapPlanes() {
    this.userHeatmap2DPlanes.forEach((item) => {
      item.planes.forEach((planeObj) => {
        this.scene.remove(planeObj);
      });
    });
  }

  removeAllLaufwegeEdges() {
    if (this.laufwegePlane) {
      this.scene.remove(this.laufwegePlane);
    }

    this.laufwegeEdges.forEach((item) => {
      item.edges.forEach((edgeObj) => {
        this.scene.remove(edgeObj);
      });
    });
  }

  updateHeatmapPlanes() {
    this.removeAllHeatmapPlanes();
    this.removeAllUserHeatmapPlanes();

    if (this.heatmapVisible && this.selectedLevelObj) {
      let tileArray = null;
      if (this.selectedHeatmapMode == 0) {
        tileArray = this.heatmap2DPlanes;
      } else {
        tileArray = this.userHeatmap2DPlanes;
      }

      let currentLvlHeatmapPlanes = _.clone(tileArray.find((x) => x.level == this.selectedLevelObj.levelName));

      if (currentLvlHeatmapPlanes) {
        if (this.filteredUserId) {
          currentLvlHeatmapPlanes.planes = currentLvlHeatmapPlanes.planes.filter((item) => {
            return item.userData.userIds.includes(this.filteredUserId);
          });
        }

        currentLvlHeatmapPlanes.planes.forEach((planeObj) => {
          this.scene.add(planeObj);
        });
      }
    }
  }

  updateLaufwegeEdges() {
    this.removeAllLaufwegeEdges();

    if (this.laufwegeVisible) {
      // Add grey Laufwege Schleier
      //
      // if (this.laufwegePlane) {
      //   this.scene.add(this.laufwegePlane);
      // } else {
      //   let geo = new THREE.PlaneBufferGeometry(1000, 1000);
      //   let mat = new THREE.MeshBasicMaterial({ color: 'black' });
      //   mat.opacity = 0.3;
      //   mat.transparent = true;
      //   let plane = new THREE.Mesh(geo, mat);
      //   plane.rotateX(-Math.PI / 2);
      //   plane.position.y = 9;
      //   plane.position.x = 0;
      //   plane.position.z = 0;
      //   this.scene.add(plane);
      //   this.laufwegePlane = plane;
      // }

      if (this.selectedLevelObj) {
        let currentLvlEdges = _.clone(this.laufwegeEdges.find((x) => x.level == this.selectedLevelObj.levelName));
        //console.warn("this.laufwegeEdges", this.laufwegeEdges);
        if (currentLvlEdges) {
          if (this.filteredUserId) {
            currentLvlEdges.edges = currentLvlEdges.edges.filter((item) => {
              return item.userData.userId == this.filteredUserId;
            });
          }

          currentLvlEdges.edges.forEach((edgeObj) => {
            this.scene.add(edgeObj);
          });
        }
      }
    }
  }

  heatmapToggle() {
    this.heatmapVisible = !this.heatmapVisible;

    if (this.heatmapVisible) {
      this.updateHeatmapPlanes();
    } else {
      this.removeAllHeatmapPlanes();
      this.removeAllUserHeatmapPlanes();
    }
  }

  laufwegeToggle() {
    this.laufwegeVisible = !this.laufwegeVisible;

    if (this.laufwegeVisible) {
      this.updateLaufwegeEdges();
    } else {
      this.removeAllLaufwegeEdges();
    }
  }

  threeOnClick(event: MouseEvent) {
    if (event && this.heatmapVisible) {
      let tileArray = null;
      if (this.selectedHeatmapMode == 0) {
        tileArray = this.heatmap2DPlanes;
      } else {
        tileArray = this.userHeatmap2DPlanes;
      }

      let currentLvlHeatmapPlanes = tileArray.find((x) => x.level == this.selectedLevelObj.levelName);

      if (currentLvlHeatmapPlanes) {
        this.raycaster.setFromCamera(this.mouseCoords, this.camera);
        let intersectionArr: any[] = this.raycaster.intersectObjects(currentLvlHeatmapPlanes.planes);

        if (intersectionArr.length > 0 && this.filteredUserId == null) {
          if (this.selectedHeatmapTile) {
            this.selectedHeatmapTile.children = [];
          }

          // wireframe
          var geo = new THREE.EdgesGeometry(intersectionArr[0].object.geometry); // or WireframeGeometry
          var mat = new THREE.LineBasicMaterial({ color: 'black', linewidth: 2 });
          var wireframe = new THREE.LineSegments(geo, mat);
          intersectionArr[0].object.add(wireframe);

          //intersectionArr[0].object.material.color.set(0xFF0000);
          this.selectedHeatmapTile = intersectionArr[0].object;
          //console.log(intersectionArr[0].object.userData.userIds);
        } else {
          if (this.selectedHeatmapTile) {
            this.selectedHeatmapTile.children = [];
            this.selectedHeatmapTile = null;
          }
        }
      }
    }
  }

  threeOnMouseMove(event: MouseEvent) {
    this.mouseCoords.x = (event.offsetX / this.threeWidth) * 2 - 1;
    this.mouseCoords.y = -(event.offsetY / this.threeHeight) * 2 + 1;
  }

  filterUserOnClick(userId) {
    if (userId != '') {
      this.filteredUserId = userId;
      this.updateLaufwegeEdges();
      this.updateHeatmapPlanes();
      this.createUserTimeline();
    }
  }

  resetMapFilter() {
    this.isEventStreamPlaying = false;
    this.clearActiveStreamTimer();

    this.filteredUserId = null;
    this.updateLaufwegeEdges();
    this.updateHeatmapPlanes();
    this.hidePositionMarker();
    this.selectedEvent = null;
    this.selectedEventIndex = -1;
    this.eventsCanGoBack = false;
    this.eventsCanGoForward = false;
  }

  onHeatmapModeChanged(event: number) {
    this.selectedHeatmapMode = event;
    this.updateHeatmapPlanes();
  }

  handleEventOnClick(event, index) {
    this.updateEventTimelineButtonStates(index);

    this.selectedEvent = event;
    this.selectedEventIndex = index;
    this.flyCameraToPos(event.x, -event.y);
    this.movePositionMarker(event.x, -event.y);
    this.doLevelChangeForEvent();

    if (this.isEventStreamPlaying == true) {
      this.playEventStream();
    }
  }

  updateEventTimelineButtonStates(index: number) {
    if (index > 0) {
      this.eventsCanGoBack = true;
    } else {
      this.eventsCanGoBack = false;
    }

    if (index < this.selectedUserTimelineEvents.length - 1) {
      this.eventsCanGoForward = true;
    } else {
      this.eventsCanGoForward = false;
    }
  }

  btnEventGoBackOnClick() {
    let oldIndex: number = this.selectedEventIndex;
    let newIndex: number = oldIndex - 1;
    this.scrollSelectedEventOnListIntoView(oldIndex, newIndex);
    this.selectedEventIndex = newIndex;
    this.changeSelectedEvent();

    if (this.isEventStreamPlaying == true) {
      this.playEventStream();
    }
  }

  btnEventGoForwardOnClick() {
    let oldIndex: number = this.selectedEventIndex;
    let newIndex: number = oldIndex + 1;
    this.scrollSelectedEventOnListIntoView(oldIndex, newIndex);
    this.selectedEventIndex = newIndex;
    this.changeSelectedEvent();

    if (this.isEventStreamPlaying == true) {
      this.playEventStream();
    }
  }

  scrollSelectedEventOnListIntoView(oldIndex: number, newIndex: number) {
    let oldScrollPerc: number = oldIndex / this.selectedUserTimelineEvents.length;
    let oldScrollAmount: number = oldScrollPerc * this.ScrollableDiv.nativeElement.scrollHeight;
    let actualScrollPos: number = this.ScrollableDiv.nativeElement.scrollTop;
    let scrollPosOffset: number = actualScrollPos - oldScrollAmount;

    let scrollPerc: number = newIndex / this.selectedUserTimelineEvents.length;
    let scrollAmount: number = scrollPerc * this.ScrollableDiv.nativeElement.scrollHeight;
    this.ScrollableDiv.nativeElement.scrollTop = scrollAmount + scrollPosOffset;
  }

  changeSelectedEvent() {
    this.updateEventTimelineButtonStates(this.selectedEventIndex);
    this.selectedEvent = this.selectedUserTimelineEvents[this.selectedEventIndex];
    this.flyCameraToPos(this.selectedEvent.x, -this.selectedEvent.y);
    this.movePositionMarker(this.selectedEvent.x, -this.selectedEvent.y);
    this.openPopupForSelectedIndex();
    this.doLevelChangeForEvent();
  }

  btnEventPlayToggle() {
    if (this.isEventStreamPlaying == false) {
      this.isEventStreamPlaying = true;
      if (this.selectedEventIndex < 0 || this.selectedEventIndex >= this.selectedUserTimelineEvents.length - 1) {
        this.scrollSelectedEventOnListIntoView(this.selectedUserTimelineEvents.length - 1, 0);
        this.selectedEventIndex = 0;
        this.changeSelectedEvent();
      }

      this.playEventStream();
    } else {
      this.isEventStreamPlaying = false;
      this.clearActiveStreamTimer();
    }
  }

  playEventStream() {
    this.clearActiveStreamTimer();

    if (this.isEventStreamPlaying == true) {
      let nextEventId: number = this.selectedEventIndex + 1;

      if (nextEventId < this.selectedUserTimelineEvents.length) {
        let curTimestamp: number = this.selectedEvent.timestamp;
        let nextTimestamp: number = this.selectedUserTimelineEvents[nextEventId].timestamp;
        let timestampDiff: number = Math.max(nextTimestamp - curTimestamp, 200);

        this.activeStreamPlayTimer = setTimeout(() => {
          this.btnEventGoForwardOnClick();
          this.playEventStream();
        }, timestampDiff);
      } else {
        this.isEventStreamPlaying = false;
        this.clearActiveStreamTimer();
      }
    }
  }

  clearActiveStreamTimer() {
    if (this.activeStreamPlayTimer != null) {
      clearTimeout(this.activeStreamPlayTimer);
      this.activeStreamPlayTimer = null;
    }
  }

  doLevelChangeForEvent() {
    if (this.selectedEvent.level != null) {
      this.updateMapLevel(this.selectedEvent.level);
    }
  }

  openPopupForSelectedIndex() {
    this.popovers.forEach((item) => {
      let id = (<any>item)._elementRef.nativeElement.id;
      if (id == 'popup' + this.selectedEventIndex.toString()) {
        item.open();
      } else {
        item.close();
      }
    });
  }

  public removeFloorPlan(scene, floorPlanTexturTiles) {
    clearAllFloorPlanTextureTiles(scene, floorPlanTexturTiles);
  }

  public addFloorPlans({ beaconMap, levelRecord }) {
    var getmapRotation = function (level) {
      if (level && level.mapRotation) {
        return level.mapRotation;
      } else {
        var mapRotation = 0;
        return mapRotation;
      }
    };

    function getModelTranslation(level) {
      let translation = {
        x: 0,
        y: 0,
      };

      if (level.mapModelTranslation) {
        translation = level.mapModelTranslation;
      }

      return translation;
    }

    const mapRotation = getmapRotation(levelRecord);
    const mapModelTranslation = getModelTranslation(levelRecord);
    const levelName = levelRecord.levelName;

    this.loadAndSetFloorPlanTexture(beaconMap, levelName, mapModelTranslation, mapRotation);
  }

  private loadAndSetFloorPlanTexture(beaconMap: any, mapLevelName: any, mapModelTranslation: any, mapRotation: any) {
    var levelDatum = _.find(beaconMap.levelData, function (o) {
      return o.levelName == mapLevelName;
    });

    var buildingTitle = levelDatum.buildingTitle;
    var levelNameReadable = levelDatum.levelDisplayName;

    FloorPlanTextureService.requestMapDimensions(
      beaconMap.locationId,
      beaconMap._id,
      beaconMap.title,
      buildingTitle,
      mapLevelName,
      levelNameReadable,
      function (dimensions) {
        var imageWidth = dimensions.width;
        var imageHeight = dimensions.height;
        var tileSize = dimensions.tileSize;
        var tilesX = imageWidth / tileSize;
        var tilesY = imageHeight / tileSize;

        this.resetCameraZoomAndPosition(beaconMap, dimensions.level);

        this.setFloorPlanTexture(
          this.scene,
          this.floorPlanTexturTiles,
          beaconMap,
          tilesX,
          tilesY,
          mapModelTranslation,
          mapRotation,
          dimensions,
          function onRequestUpdate() {
            console.log('onRequestUpdate');
          }
        );
      }.bind(this)
    );
  }

  public setFloorPlanTexture(
    scene: any,
    floorPlanTexturTiles,
    beaconMap,
    maxX,
    maxY,
    mapModelTranslation,
    mapRotation,
    dimensions,

    onRequestUpdate
  ) {
    if (!scene || !beaconMap) {
      return;
    }

    // bounds = Grenzen/Begrenzungen
    var bounds = determineFloorPlanTextureBounds(beaconMap, dimensions.level);
    var textureToWorldFactor = (bounds.xMax - bounds.xMin) / (maxX * dimensions.tileSize);

    dimensions.textureToWorldFactor = textureToWorldFactor;
    dimensions.worldTileSize = dimensions.tileSize * textureToWorldFactor;
    dimensions.bounds = bounds;
    //dimensions.mapModelTranslation = mapModelTranslation;
    dimensions.mapModelTranslation = { x: 0, y: 0, z: 0 };

    var rotation = {
      angle: mapRotation,
      x: bounds.xMin,
      y: bounds.yMin,
    };

    clearAllFloorPlanTextureTiles(scene, floorPlanTexturTiles);

    const maxAnisotropy = 16; // ?
    const concurrentTileMapReqCount = 2;

    const reqConfigs = [];
    for (var x = 0; x <= maxX; x++) {
      for (var y = 0; y <= maxY; y++) {
        const requestConfig = {
          x,
          y,
          rotation,
          dimensions,
          maxAnisotropy,
        };

        reqConfigs.push(requestConfig);
      }
    }

    const concurrentThreads = {};

    console.time('all-reqs');
    from(reqConfigs)
      .pipe(
        mergeMap(
          (requestConfig: any) => {
            concurrentThreads[requestConfig.x + '/' + requestConfig.y] = 'RN';

            const { x, y, rotation, dimensions, maxAnisotropy } = requestConfig;
            return from(
              FloorPlanTextureService.loadFloorPlaneTileMeshAsync(x, y, rotation, dimensions, maxAnisotropy)
            ).pipe(map((tileMesh) => ({ requestConfig, tileMesh })));
          },
          null,
          concurrentTileMapReqCount
        ),
        tap(({ requestConfig }) => {
          delete concurrentThreads[requestConfig.x + '/' + requestConfig.y];
        })
      )
      .subscribe(
        ({ tileMesh }) => {
          addTextureTileToScene(scene, floorPlanTexturTiles, tileMesh);
        },
        null,
        () => {
          console.timeEnd('all-reqs');
        }
      );
  }

  private concatMap() {
    return undefined;
  }
}

function calcBoundingBox(nodes) {
  var allXCoords = _.map(nodes, 'x');
  var allYCoords = _.map(nodes, 'y');

  //console.log("DetermineBounds: allXCoords",allXCoords);
  //console.log("DetermineBounds: allYCoords",allYCoords);
  var bounds = {
    xMin: _.min(allXCoords),
    xMax: _.max(allXCoords),
    yMin: _.min(allYCoords),
    yMax: _.max(allYCoords),
  };

  return bounds;
}

function determineFloorPlanTextureBounds(beaconMap, mapLevelName): any {
  var beaconNodes = beaconMap.beaconNodes;
  var beaconNodeOnCurLevelAndAnchor = _.filter(beaconNodes, { level: mapLevelName, isTextureAnchorNode: true });
  var beaconNodeOnCurLevel = _.filter(beaconNodes, { level: mapLevelName });

  var boundingBoxWholeLevel = calcBoundingBox(beaconNodeOnCurLevel);
  var boundingBoxLevelAndAnchor = calcBoundingBox(beaconNodeOnCurLevelAndAnchor);

  if (
    boundingBoxWholeLevel.xMax > boundingBoxLevelAndAnchor.xMax ||
    boundingBoxWholeLevel.xMin < boundingBoxLevelAndAnchor.xMin ||
    boundingBoxWholeLevel.yMax > boundingBoxLevelAndAnchor.yMax ||
    boundingBoxWholeLevel.yMin < boundingBoxLevelAndAnchor.yMin
  ) {
    // alert("Far away nodes?? - please check" + JSON.stringify(boundingBoxWholeLevel) + " -- " + JSON.stringify(boundingBoxLevelAndAnchor));
  }

  // this method needs min 2 Anchor nodes to determine Bounds.
  if (beaconNodeOnCurLevelAndAnchor.length != 2) {
    alert(
      'This level needs 2 Anchor nodes (currently there are more or less)!' +
        JSON.stringify(_.map(beaconNodeOnCurLevelAndAnchor, 'beaconId'))
    );
    return {};
  } else {
    console.log('DetermineBounds: beaconNodeOnCurLevel', beaconNodeOnCurLevelAndAnchor);

    return boundingBoxLevelAndAnchor;
  }
}

function clearAllFloorPlanTextureTiles(scene, floorPlanTexturTiles) {
  if (floorPlanTexturTiles.length > 0) {
    _.each(floorPlanTexturTiles, function (tile) {
      scene.remove(tile);
    });
    floorPlanTexturTiles.length = 0;
  }
  // requestAnimation();
}

function addTextureTileToScene(scene, floorPlanTexturTiles, tileMesh) {
  scene.add(tileMesh);
  floorPlanTexturTiles.push(tileMesh);
}
