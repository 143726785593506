import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserAccountService } from '../../user-account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  urlUsername: string;
  user: User = new User();
  failedLoginAttempt: boolean = false;

  constructor(private route: ActivatedRoute, private accountService: UserAccountService, private router: Router) {}

  ngOnInit() {
    const id: string = this.route.snapshot.paramMap.get('id');

    if (id !== null) {
      this.urlUsername = id;
      this.user.username = id;
    }
  }

  async logInButtonClicked() {
    let didSuccessfullyLogin = await this.accountService.logInUser(this.user.username, this.user.password);
    if (didSuccessfullyLogin) {
      console.log('account details correct.');
      this.failedLoginAttempt = false;

      // this.router.navigate(['dashboard']);
      this.router.navigate(['wz-portal-hub']);

      // alert('Login successful. Redirecting to dashboard...');

    } else {
      console.log('wrong account details');
      this.failedLoginAttempt = true;
    }
  }
}
