import * as THREE from 'three-full';

import { floorPlanTextureCacheService as FloorPlanTextureCacheService } from './floor-plan-texture-tile-cache.service.js';

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

var FloorPlanTextureService = function () {
  var TEXTURE_SRC_URL = 'https://floorplans.wegzwei.com/';

  var TEXTURE_MODE_PVR = 'PVR_TEXTURE_MODE';
  var TEXTURE_MODE_JPG = 'JPG_TEXTURE_MODE';

  var _textureMode;
  var _loader;
  var _textureTileFileSuffix;

  /**
   * Initializes the Floorplan Texture service
   */
  var init = function () {
    // if (!Detector.webgl) Detector.addGetWebGLMessage();
    //
    // if (isPlatformIOS()) {
    //   _textureMode = TEXTURE_MODE_PVR;
    // } else {
    //   _textureMode = TEXTURE_MODE_JPG;
    // }

    _textureMode = TEXTURE_MODE_JPG;

    switch (_textureMode) {
      case TEXTURE_MODE_PVR:
        //initialize loader PVR Texture Loader.
        _loader = new THREE.PVRLoader();
        _textureTileFileSuffix = '.pvr';
        break;

      case TEXTURE_MODE_JPG:
      default:
        // set TEXTURE_MODE_JPG as default texture mode
        _textureMode = TEXTURE_MODE_JPG;

        //initialize loader basic Texture Loader.
        _loader = new THREE.TextureLoader();
        _textureTileFileSuffix = '.jpg';
    }
  };

  var replaceUmlaute = function (str) {
    if (!str) {
      return str;
    }

    const umlautMap = {
      '\u00dc': 'UE',
      '\u00c4': 'AE',
      '\u00d6': 'OE',
      '\u00fc': 'ue',
      '\u00e4': 'ae',
      '\u00f6': 'oe',
      '\u00df': 'ss',
    };

    return str
      .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
        const big = umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
      })
      .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), (a) => umlautMap[a]);
  };

  /**
   * Loads the the texture
   * @param x
   * @param y
   * @param dimensions
   * @param maxAnisotropy
   * @param onSuccess
   */
  var loadFloorPlaneTexture = function (x, y, dimensions, maxAnisotropy, onSuccess) {
    var textureTilePath =
      TEXTURE_SRC_URL +
      '/' +
      dimensions.mapId +
      '/' +
      replaceUmlaute(dimensions.locationName) +
      '/' +
      replaceUmlaute(dimensions.buildingName) +
      '/' +
      replaceUmlaute(dimensions.buildingName) +
      ' ' +
      replaceUmlaute(dimensions.levelNameReadable) +
      '-small.jpg_tiles/tile_' +
      x +
      '_' +
      y +
      _textureTileFileSuffix;

    _loader.load(
      textureTilePath,
      function (texture) {
        texture.generateMipmaps = _textureMode !== TEXTURE_MODE_PVR;

        texture.anisotropy = maxAnisotropy;
        texture.magFilter = THREE.LinearFilter;
        texture.minFilter = THREE.LinearFilter;
        texture.needsUpdate = true;
        onSuccess(texture, textureTilePath);
      },
      undefined,
      function (error) {
        console.error('An error happened while loading Texture with url="' + textureTilePath + '".', error);
      }
    );
  };

  /**
   * Determine whether the device is an IOS device or not.
   * @returns {boolean} true if device is running on IOS, otherwise false
   */
  var isPlatformIOS = function () {
    var iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

    if (!!navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Creates the Material for a Tile
   * @param texture
   * @returns .PVR ? {THREE.MeshBasicMaterial} : {THREE.MeshLambertMaterial}
   */
  function createTileMaterial(texture) {
    var material;

    switch (_textureMode) {
      case TEXTURE_MODE_PVR:
        material = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.DoubleSide,
        });
        console.log('material ios', material);
        break;

      default:
        material = new THREE.MeshLambertMaterial({
          map: texture,
          side: THREE.DoubleSide,
        });
        // console.log("material Desktop" , material);
        break;
    }
    return material;
  }

  /**
   * Scales and Rotates the Tile
   * @param tileMesh
   * @returns {*}
   */
  function setTileRotateAndScale(tileMesh) {
    switch (_textureMode) {
      case TEXTURE_MODE_PVR:
        tileMesh.scale.y = -1;

        tileMesh.rotateZ = Math.PI / 180;
        tileMesh.rotateX = Math.PI / 180;
        tileMesh.rotateY = Math.PI / 180;
        break;
      case TEXTURE_MODE_JPG:
        //tileMesh.scale.z = -1;
        break;
    }
    return tileMesh;
  }

  /**
   * Moves the tile to the defined Position
   *
   * @param x
   * @param y
   * @param dimensions
   * dimensions.worldTileSize := to-map-scaling scaled tile size
   * @param tileMesh
   * @param bitmapSize {Object} - Contains to-map-scaling scaled dimensions of bitmap
   * @returns {*}
   */

  function setTilePosition(x, y, rotation, dimensions, tileMesh, bitmapSize) {
    // Get initial position
    var xOffset = dimensions.bounds.xMin + x * dimensions.worldTileSize;
    var yOffset = dimensions.bounds.yMax - y * dimensions.worldTileSize;

    // Add mapModel translation offset if defined; translation is 0 in most cases
    xOffset += dimensions.mapModelTranslation.x;
    yOffset += dimensions.mapModelTranslation.y;

    // Add offset to fill-up to worldTileSize, if bitmap size is smaller than worldTileSize
    if (bitmapSize.width < dimensions.worldTileSize) {
      xOffset -= (dimensions.worldTileSize - bitmapSize.width) / 2;
    }
    if (bitmapSize.height < dimensions.worldTileSize) {
      yOffset += (dimensions.worldTileSize - bitmapSize.height) / 2;
    }

    // Add half of scaledTileSize as translation, because tile seems to be rendered from the middle
    tileMesh.position.x = xOffset + dimensions.worldTileSize / 2;
    tileMesh.position.y = -0.1; // oroginall -0.1
    tileMesh.position.z = -(yOffset - dimensions.worldTileSize / 2);
    tileMesh.rotation.x = -Math.PI / 2;

    //rotation
    var theta = rotation.angle * (Math.PI / 180);
    tileMesh.rotateOnAxis(new THREE.Vector3(0, 0, 1), theta); // rotate the OBJECT

    var point = new THREE.Vector3(rotation.x, 0, -rotation.y);
    var axis = new THREE.Vector3(0, 1, 0);
    tileMesh.position.sub(point);
    tileMesh.position.applyAxisAngle(axis, theta); // rotate the POSITION
    tileMesh.position.add(point);

    return tileMesh;
  }

  /**
   * Creates the mesh for one Tile
   * @param x
   * @param y
   * @param dimensions
   * @param texture
   * @returns {*}
   */
  function createTileMesh(x, y, rotation, dimensions, texture) {
    var material = createTileMaterial(texture);

    var bitmapSize = {
      width: texture.image.width * dimensions.textureToWorldFactor,
      height: texture.image.height * dimensions.textureToWorldFactor,
    };

    // Keep bitmapImage aspect ratio in planeGeometry, but if this differs from square tileSize,
    // then an offset is added in setTilePosition()
    var geometry = new THREE.PlaneGeometry(bitmapSize.width, bitmapSize.height, 0);
    geometry.uvsNeedUpdate = true;
    var tileMesh = new THREE.Mesh(geometry, material);

    tileMesh = setTilePosition(x, y, rotation, dimensions, tileMesh, bitmapSize);
    tileMesh = setTileRotateAndScale(tileMesh);

    var box = new THREE.Box3().setFromObject(tileMesh);

    return tileMesh;
  }

  /**
   * Public: loads texture and creates a texture tile 3D Object the
   * @param x
   * @param y
   * @param dimensions
   * @param maxAnisotropy
   * @param onSuccess
   */
  var loadFloorPlaneTileMesh = function (x, y, rotation, dimensions, maxAnisotropy, onSuccess, timeout) {
    // Get tile from cache
    //if (FloorPlanTextureCacheService.hasTile(dimensions.locationId, dimensions.level, x, y)) {
    //disable cache for a while (2020-02-10)

    if (0) {
      var tile = FloorPlanTextureCacheService.getTile(dimensions.locationId, dimensions.level, x, y);
      onSuccess(tile);
      return;
    }

    setTimeout(function () {
      // Create new Mesh
      loadFloorPlaneTexture(x, y, dimensions, maxAnisotropy, function (texture, textureTilePath) {
        var tileMesh = createTileMesh(x, y, rotation, dimensions, texture);
        FloorPlanTextureCacheService.addTile(dimensions.locationId, dimensions.level, x, y, tileMesh);
        onSuccess(tileMesh);
      });
    }, timeout);
  };

  var loadFloorPlaneTileMeshAsync = function (x, y, rotation, dimensions, maxAnisotropy) {
    return new Promise((resolve, reject) => {
      if (0) {
        var tile = FloorPlanTextureCacheService.getTile(dimensions.locationId, dimensions.level, x, y);
        onSuccess(tile);
        return;
      }

      // Create new Mesh
      loadFloorPlaneTexture(x, y, dimensions, maxAnisotropy, function (texture, textureTilePath) {
        var tileMesh = createTileMesh(x, y, rotation, dimensions, texture);
        FloorPlanTextureCacheService.addTile(dimensions.locationId, dimensions.level, x, y, tileMesh);
        // onSuccess(tileMesh);
        resolve(tileMesh);
      });
    });
  };

  /**
   * Public: Requests the the Texture dimensions for a specific mapId and Level
   * Caches the dimensions in localStorage, because json objects can not be cached
   * safely using http headers. If browser is online, always request fresh dimensions, use
   * cache as fallback.
   * @param locationId
   * @param level
   * @param onSuccess
   */
  var requestMapDimensions = async function (
    locationId,
    mapId,
    locationName,
    buildingName,
    level,
    levelNameReadable,
    onSuccess
  ) {
    var dimensionsCacheJson = localStorage.getItem('dimensionsCache');
    var dimensionsCache;
    if (dimensionsCacheJson) {
      dimensionsCache = JSON.parse(dimensionsCacheJson);
    } else {
      dimensionsCache = {};
    }

    var relTexturePath =
      TEXTURE_SRC_URL +
      '/' +
      mapId +
      '/' +
      replaceUmlaute(locationName) +
      '/' +
      replaceUmlaute(buildingName) +
      '/' +
      replaceUmlaute(buildingName) +
      ' ' +
      replaceUmlaute(levelNameReadable) +
      '-small.jpg.dimensions.json';

    var textureServerIsOffline = false;

    console.log('donotoverschlage', 10);
    await fetchWithTimeout(relTexturePath, { timeout: 200 }).then(
      function (success) {
        console.log('donotoverschlage', 15);
      },
      function (error) {
        //if we're here, then we have no response from texture server within 200ms - the texture server is probably not reachable - so force to use cache
        textureServerIsOffline = true;

        console.log('donotoverschlage', 20);
      }
    );

    console.log('donotoverschlage', 30);

    function isCached() {
      return !navigator.onLine && dimensionsCache && dimensionsCache[relTexturePath];
    }

    if (isCached() || textureServerIsOffline) {
      console.log('Attempting loading textures from cache ...');

      var data = dimensionsCache[relTexturePath];
      if (!data) {
        console.log('Cache miss!');
        return;
      }

      onSuccess({
        locationId: locationId,
        level: level,
        levelNameReadable: levelNameReadable,
        width: data.width,
        height: data.height,
        pageWidth: data.pageWidth,
        pageHeight: data.pageHeight,
        tileSize: data.tileSize,
      });
    } else {
      console.log('loading textures from server');

      fetch(relTexturePath, {
        headers: {
          accept: 'application/json',
        },
      })
        .then(function (fetchResponse) {
          return fetchResponse.json();
        })

        .then(function (responseJson) {
          const response = { data: responseJson };
          dimensionsCache[relTexturePath] = response.data;
          dimensionsCacheJson = JSON.stringify(dimensionsCache);
          localStorage.setItem('dimensionsCache', dimensionsCacheJson);

          onSuccess({
            locationId: locationId,
            mapId: mapId,
            locationName: locationName,
            buildingName: buildingName,
            level: level,
            levelNameReadable: levelNameReadable,
            width: response.data.width,
            height: response.data.height,
            pageWidth: response.data.pageWidth,
            pageHeight: response.data.pageHeight,
            tileSize: response.data.tileSize,
          });
        });
    }

    // }, 400);
  };

  // var requestPageDimensions = function (
  //   locationId,
  //   mapId,
  //   locationName,
  //   buildingName,
  //   level,
  //   levelNameReadable,
  //   onSuccess
  // ) {
  //   var pageSizePath =
  //     TEXTURE_SRC_URL +
  //     '/' +
  //     mapId +
  //     '/' +
  //     replaceUmlaute(locationName) +
  //     '/' +
  //     replaceUmlaute(buildingName) +
  //     '/' +
  //     replaceUmlaute(buildingName) +
  //     ' ' +
  //     replaceUmlaute(levelNameReadable) +
  //     '.jpg.pagesize.json';
  //
  //   //after 400ms move on (to make sure we know the result if the texture server is online or offline)
  //
  //   console.log('loading pagesize from server');
  //   $http({
  //     method: 'GET',
  //     url: pageSizePath,
  //     headers: {
  //       accept: 'application/json',
  //     },
  //   }).then(
  //     function (response) {
  //       console.log('here we are');
  //       onSuccess({
  //         locationId: locationId,
  //         mapId: mapId,
  //         locationName: locationName,
  //         buildingName: buildingName,
  //         level: level,
  //         levelNameReadable: levelNameReadable,
  //         pageWidth: response.data.pageWidth,
  //         pageHeight: response.data.pageHeight,
  //       });
  //     },
  //     function (error) {
  //       onSuccess({
  //         error: 'no page available: ' + error,
  //       });
  //     }
  //   );
  // };

  init();

  return {
    loadFloorPlaneTileMesh: loadFloorPlaneTileMesh,
    loadFloorPlaneTileMeshAsync: loadFloorPlaneTileMeshAsync,
    requestMapDimensions: requestMapDimensions,
    // requestPageDimensions: requestPageDimensions,
  };
};

const floorPlanTextureService = new FloorPlanTextureService();

export { floorPlanTextureService };
